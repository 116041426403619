import { useAuthenticationFlow } from "@kartverket/frontend-aut-lib";
import { autFlowConfiguration } from "@kartverket/frontend-aut-lib/dist/authFlowConfiguration";
import { Text, VStack } from "@kvib/react";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import {
  BestillerType,
  PrivatpersonBestiller,
} from "../../api/bestillApiDataTypes";
import { useBestillerContext } from "../../shared/context/BestillerContext";
import AnimatedStepper from "./Stepper/AnimatedStepper";

type Props = {
  children?: React.ReactNode;
};

const Bestill = ({ children }: Props) => {
  const location = useLocation();
  const { setBestiller } = useBestillerContext();
  const { isAuthenticatedFunc, handleAuthenticateFunc } =
    useAuthenticationFlow();
  const config = autFlowConfiguration().getAutConfig();

  useEffect(() => {
    const exampleBestiller: PrivatpersonBestiller = {
      type: BestillerType.PRIVATPERSON,
      fodselsnummer: "07089800000",
      navn: "navn",
      adresse: "adresse",
      postnummer: "0358",
    };

    setBestiller(exampleBestiller);
  }, [setBestiller]); // TODO: Override this with the fetched / inputted user (https://kartverket.atlassian.net/browse/TE-1032)

  if (!config) {
    return <Text> Laster... </Text>;
  }

  if (!isAuthenticatedFunc() || !autFlowConfiguration().getAutConfig()) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV !== "development") {
      handleAuthenticateFunc(window.location.href);
      return <Text>Logger inn...</Text>;
    }
  }

  const steps = [
    { title: "Bestill", route: "/bestill" },
    { title: "Levering og betaling", route: "/bestill/levering-og-betaling" },
    { title: "Kvittering", route: "/bestill/kvittering" },
  ];

  // find index of current step
  const currentStepIndex = steps.findIndex(
    (step) => step.route === location.pathname,
  );

  return (
    <>
      <VStack paddingBottom="50px">
        <AnimatedStepper activeStep={currentStepIndex} steps={steps} />
      </VStack>

      {children}
    </>
  );
};

export default Bestill;
