import { useEffect, useRef,useState } from "react";
import { useSearchParams } from "react-router-dom";

const useOrgnrSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const orgnr = searchParams.get("orgnr") ?? "";
  const [orgnavn, setOrgnavn] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const hasFetched = useRef(false);

  const fetchOrgnavn = async (newOrgnr: string): Promise<string | null> => {
    setOrgnavn("");
    setErrorMsg("");

    if (/^\d{9}$/.test(newOrgnr)) {
      try {
        const response = await fetch(
            `https://data.brreg.no/enhetsregisteret/api/enheter/${newOrgnr}`,
        );
        if (response.status === 404) {
          setErrorMsg("Organisasjonen finnes ikke");
          return null;
        }

        const data = await response.json();
        return data.navn;
      } catch (error) {
        console.error("Error fetching data:", error);
        setErrorMsg("Kunne ikke hente data");
        return null;
      }
    }

    return null;
  };

  const handleOrgnrChange = async (newOrgnr: string) => {
    setSearchParams({ ...Object.fromEntries(searchParams), orgnr: newOrgnr });

    const orgnavn = await fetchOrgnavn(newOrgnr);
    setOrgnavn(orgnavn || "");
  };

  useEffect(() => {
    (async function () {
      // Hent navn ved initiell lasting, hvis ikke allerede hentet
      if (orgnr && !hasFetched.current) {
        hasFetched.current = true;
        const orgnavn = await fetchOrgnavn(orgnr);
        setOrgnavn(orgnavn || "");
      }
    })();
  }, [orgnr]);

  return {
    orgnr,
    orgnavn,
    errorMsg,
    handleOrgnrChange,
  };
};

export default useOrgnrSearch;