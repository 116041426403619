import {
  Box,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Text,
  useMediaQuery,
} from "@kvib/react";
import { Borettslagsandel } from "api/apiDataTypes";
import { useBorettslag } from "api/useBorettslag";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  AccordionPanelWrapper,
  AccordionPanelWrapperError,
  AccordionPanelWrapperSkeltonLoader,
} from "shared/components/AccordionPanelWrapper";
import { BestillUtskriftButton } from "shared/components/BestillUtskriftButton";
import { InfoPopover } from "shared/components/InfoButton";
import PanelData from "shared/components/PanelData";
import { VisGrunnbokButtonBorettslag } from "shared/components/VisGrunnbokButtonBorettslag";
import tokens from "shared/styles/tokens";

export const BorettslagPanel = () => {
  const ident = useParams();
  const { data: borettslag, error, isLoading } = useBorettslag(ident);

  const [query, setQuery] = useState("");
  const [isSmall] = useMediaQuery(`(max-width: ${tokens.breakpoints.sm})`);

  if (isLoading) {
    return <AccordionPanelWrapperSkeltonLoader title="Borettslag" />;
  }

  if (error) {
    return (
      <AccordionPanelWrapperError
        title="Borettslag"
        errorText="Kunne ikke hente borettslag"
      />
    );
  }

  const title = `Borettslagandeler (${
    borettslag.borettslagMedAndeler[0].andeler.filter(
      (andel) => !andel.isUtgaatt,
    ).length ?? 0
  })`;

  const headers = [
    <HStack key="1">
      <b>Borettslag</b>
      <InfoPopover color="blue">
        <Text>
          Organisasjonsnummeret som borettslaget er registrert med i{" "}
          <Link href="https://www.brreg.no/" isExternal>
            Enhetsregisteret
          </Link>
          .
        </Text>
      </InfoPopover>
    </HStack>,
    <HStack key="2">
      <b>Andelsnummer</b>
      <InfoPopover color="blue">
        <Text>
          Alle boliger i et borettslag er knyttet til et andelsnummer som er
          registrert i grunnboken.
        </Text>
      </InfoPopover>
    </HStack>,
    <HStack key="3">
      <b>Adresse</b>
      <InfoPopover color="blue">
        <Text>
          Dette er adressen som er registrert for borettslagsandelen.{" "}
          <Link
            href="https://kartverket.no/eiendom/borettslag/adresser-i-burettslag"
            isExternal
          >
            Les mer om adressering av borettslagsandeler
          </Link>
        </Text>
      </InfoPopover>
    </HStack>,
    <HStack key="4">
      <b>Grunnbok</b>
      <InfoPopover color="blue">
        <Text>
          Vis hva som er tinglyst (krever innlogging), eventuelt bestill
          bekreftet grunnboksutskrift eller kopi av tinglyst dokument.{" "}
          <Link
            href="https://kartverket.no/eiendom/bestille-fra-grunnboken/sjekk-hva-som-er-tinglyst"
            isExternal
          >
            Les mer om grunnboken
          </Link>
        </Text>
      </InfoPopover>
    </HStack>,
  ];

  const rowElements = (
    andel: Borettslagsandel,
    borettslagnummer: string,
    isSmall: boolean,
  ) => [
    <Box key="1">{borettslagnummer}</Box>,
    <Box key="2">{andel.andelsnummer}</Box>,
    <Box key="3">{andel.offisiellAdresse}</Box>,
    <Flex key="4" gap="5" flexWrap="wrap">
      <VisGrunnbokButtonBorettslag
        andelsnummer={andel.andelsnummer}
        borettslagnummer={borettslagnummer}
        size="md"
        asLink={isSmall}
      />
      <BestillUtskriftButton size="md" asLink={isSmall} />
    </Flex>,
  ];

  const rows =
    borettslag.borettslagMedAndeler.flatMap((borettslag) =>
      borettslag.andeler
        .filter((andel) => !andel.isUtgaatt)
        .filter(
          ({ offisiellAdresse, andelsnummer }) =>
            offisiellAdresse.toLowerCase().includes(query.toLowerCase()) ||
            andelsnummer.toString().includes(query),
        )
        .map((andel) => {
          return {
            title: String(andel.andelsnummer),
            subtitle: String(andel.offisiellAdresse),
            data: rowElements(andel, borettslag.borettslagnummer, isSmall),
          };
        }),
    ) ?? [];

  return (
    <AccordionPanelWrapper title={title}>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <span className="material-symbols-outlined">search</span>
        </InputLeftElement>
        <Input
          type="search"
          placeholder="Finn en spesifikk adresse eller et andelsnummer"
          onChange={(e) => setQuery(e.target.value.trim())}
        />
      </InputGroup>
      {rows.length > 0 ? (
        <PanelData caption="Borettslag" headers={headers} rows={rows} />
      ) : (
        <Text as="i" textColor="gray.500">
          Ingen borettslagsandeler i listen samsvarer med søketeksten.
        </Text>
      )}
    </AccordionPanelWrapper>
  );
};
