import { Divider, HStack, Text, VStack } from "@kvib/react";
import React from "react";

import { PRIS_PR_PRODUKT } from "../../../shared/constants";
import { useBestillingerContext } from "../../../shared/context/BestillingerContext";
import BestillDisplayObject from "../BestillDisplayObjects/BestillDisplayObject";

const BestillingoversiktKvittering = () => {
  const { bestillinger } = useBestillingerContext();

  const isEmpty = bestillinger.length === 0;
  const colorScheme = isEmpty ? "gray" : "green";

  return (
    <VStack>
      <VStack
        backgroundColor={`${colorScheme}.100`}
        padding={10}
        rounded="xl"
        gap="28px"
        alignItems="flex-start"
      >
        <Text fontSize="xl" fontWeight="bold">
          Bestilte dokumenter
        </Text>
        <Divider borderColor="gray" />
        <VStack width="300px" gap="28px">
          {bestillinger?.map((bestilling, index) => {
            return (
              <VStack key={index} alignItems="flex-start" width="100%">
                <BestillDisplayObject bestilling={bestilling} />
              </VStack>
            );
          })}
        </VStack>
        <Divider borderColor="gray" />
        <HStack width="100%" justifyContent="space-between">
          <VStack alignItems="flex-start">
            <Text fontWeight="bold">Betalt</Text>
          </VStack>
          <Text> {bestillinger.length * PRIS_PR_PRODUKT},- </Text>
        </HStack>
        <HStack></HStack>
      </VStack>
    </VStack>
  );
};

export default BestillingoversiktKvittering;
