import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  HStack,
  Link,
  Text,
  useMediaQuery,
} from "@kvib/react";
import { Festegrunn } from "api/apiDataTypes";
import { useFestegrunner } from "api/useFestegrunner";
import { getIdentString } from "features/EiendomInfo/EiendomInfoHeader/getIdentString";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  AccordionPanelWrapper,
  AccordionPanelWrapperError,
  AccordionPanelWrapperSkeltonLoader,
} from "shared/components/AccordionPanelWrapper";
import { BestillUtskriftButton } from "shared/components/BestillUtskriftButton";
import { InfoPopover } from "shared/components/InfoButton";
import PanelData from "shared/components/PanelData";
import { VisGrunnbokButton } from "shared/components/VisGrunnbokButton";
import tokens from "shared/styles/tokens";

export const FestegrunnerPanel: React.FC = () => {
  const ident = useParams();
  const { data: festegrunner, isLoading, error } = useFestegrunner(ident);
  const [isSmall] = useMediaQuery(`(max-width: ${tokens.breakpoints.sm})`);

  const title = `Festegrunner (${festegrunner?.length ?? 0})`;

  const headers = [
    <HStack key="1">
      <b>Festegrunn</b>
      <InfoPopover color="blue">
        <Text>
          Klikk på matrikkelnummeret for å se eiendomsdata for festegrunnen.
        </Text>
      </InfoPopover>
    </HStack>,
    <HStack key="2">
      <b>Grunnbok</b>
      <InfoPopover color="blue">
        <Text>
          Vis hva som er tinglyst (krever innlogging), eventuelt bestill
          bekreftet grunnboksutskrift eller kopi av tinglyst dokument.{" "}
          <Link
            href="https://kartverket.no/eiendom/bestille-fra-grunnboken/sjekk-hva-som-er-tinglyst"
            isExternal
          >
            Les mer om grunnboken
          </Link>
        </Text>
      </InfoPopover>
    </HStack>,
  ];

  const rowElements = (festegrunn: Festegrunn, isSmall: boolean) => [
    <Box key="1">
      <Link
        href={`/eiendom/${getIdentString(
          festegrunn.matrikkelenhetIdent,
        ).replace("-", "/")}`}
      >
        {getIdentString(festegrunn.matrikkelenhetIdent)}
      </Link>
    </Box>,
    <Flex key="2" gap="5" flexWrap="wrap">
      {festegrunn.isTinglyst ? (
        <>
          <VisGrunnbokButton
            kommunenr={festegrunn.matrikkelenhetIdent.kommunenr}
            gaardsnr={festegrunn.matrikkelenhetIdent.gaardsnr}
            bruksnr={festegrunn.matrikkelenhetIdent.bruksnr}
            festenr={festegrunn.matrikkelenhetIdent.festenr}
            seksjonsnr={festegrunn.matrikkelenhetIdent.seksjonsnr}
            size="md"
            asLink={isSmall}
          />
          <BestillUtskriftButton size="md" asLink={isSmall} />
        </>
      ) : (
        <Alert status="info" width="auto">
          <AlertIcon />
          Festegrunnen er ikke tinglyst
        </Alert>
      )}
    </Flex>,
  ];

  const rows = useMemo(() => {
    return (
      festegrunner?.map((festegrunn) => ({
        title: `Festegrunn ${festegrunn.matrikkelenhetIdent.festenr}`,
        data: rowElements(festegrunn, isSmall),
      })) ?? []
    );
  }, [festegrunner, isSmall]);

  if (isLoading) {
    return <AccordionPanelWrapperSkeltonLoader title="Festegrunner" />;
  }

  if (error) {
    return (
      <AccordionPanelWrapperError
        title="Festegrunner"
        errorText="Kunne ikke hente festegrunner"
      />
    );
  }

  return (
    <AccordionPanelWrapper title={title}>
      <PanelData caption="Festegrunner" headers={headers} rows={rows} />
    </AccordionPanelWrapper>
  );
};
