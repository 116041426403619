import { AxiosError } from "axios";

export type PartialIdent = Partial<MatrikkelenhetIdent>;

export type ServerState<T> =
  | {
      data: T;
      error: undefined;
      isLoading: false;
    }
  | {
      data: undefined;
      error: Error;
      isLoading: false;
    }
  | {
      data: undefined;
      error: undefined;
      isLoading: true;
    };

export interface SearchResponse {
  flereResultater: boolean;
  matrikkelenheter: EnkelAdresse[];
}

export interface EnkelAdresse {
  boligType: BoligType;
  borettslagnavn: string;
  bruksnr: number;
  festenr: number;
  gaardsnr: number;
  id: number;
  kommunenavn: string;
  kommunenr: string;
  seksjonsnr: number;
  veiadresse: string;
}

export enum BoligType {
  Borettslag = "BORETTSLAG",
  Borettslagandel = "BORETTSLAGANDEL",
  Eiendom = "EIENDOM",
  IkkeSeksjonertEiendom = "IKKE_SEKSJONERT_EIENDOM",
  Sameie = "SAMEIE",
  Seksjon = "SEKSJON",
}

export interface BorettslagMedAndeler {
  andeler: Borettslagsandel[];
  borettslagnavn: string;
  borettslagnummer: string;
  historisk: boolean;
}

export interface Borettslagsandel {
  andelsnummer: number;
  isUtgaatt: boolean;
  offisiellAdresse: string;
}

export interface Borettslag {
  borettslag: boolean;
  borettslagMedAndeler: BorettslagMedAndeler[];
}

export interface Koordinater {
  epsgKode?: number;
  feilmelding?: string;
  xpos?: number;
  ypos?: number;
}

export interface MatrikkelenhetIdent {
  bruksnr: string;
  festenr?: string;
  gaardsnr: string;
  kommunenr: string;
  seksjonsnr?: string;
}

export interface EiendomInfo {
  arealmerknad?: string;
  bruksnavn?: string;
  historiskOppgittAreal?: number;
  isFestegrunner?: boolean;
  isGrunnforurensning?: boolean;
  isHarRegistrertGrunnerverv?: boolean;
  isJordskifteKrevd?: boolean;
  isKulturminner?: boolean;
  isOppmalingIkkeFullfort?: boolean;
  isSeksjonert?: boolean;
  isTinglyst?: boolean;
  isUtgaatt?: boolean;
  kommunenavn?: string;
  koordinater?: Koordinater;
  matrikkelenhetId: number;
  matrikkelenhetident?: MatrikkelenhetIdent;
  oppgittAreal?: number;
  type?: string;
  underSammenslaaing?: string;
  undertype?: string;
}

export interface KoordinaterForKartutsnitt {
  koordinater: number[];
  teigid: number;
}

export interface BasicInfo {
  adresse?: string;
  borettslagnavn?: string;
  kommune: string;
  matrikkelIdent?: MatrikkelenhetIdent;
  postnummeromraade?: string;
}

export interface Seksjon {
  ident: MatrikkelenhetIdent;
  isTinglyst: boolean;
  type?: string;
}

export interface Kulturminne {
  bygningsnummer: string;
  kulturminneArt: string;
  lokalitetsnummer: string;
  vernetype: string;
}

export interface Festegrunn {
  isTinglyst: boolean;
  matrikkelenhetIdent: MatrikkelenhetIdent;
}

export interface Adresse {
  adresse: string;
  grunnkrets: string;
  kirkesokn: string;
  matrikkelenhetIdent: MatrikkelenhetIdent;
  postnummeromraade: string;
  valgkrets: string;
}

export interface Teig {
  areal: number;
  arealmerknad: string;
  isFlereMatrikkelenheter: boolean;
  isUavklarteGrenser: boolean;
  isUregistrertJordsameie: boolean;
  koordinater: Koordinater;
  medium: string;
  type: string;
}

export interface Bygning {
  bygningsendring: string;
  bygningsnummer: string;
  isFredet: boolean;
  isSefrak: boolean;
  naeringsgruppe: string;
  status: string;
  type: string;
}

export interface Driftsmelding {
  beskrivelse?: string;
  lenke?: string;
  lenketekst?: string;
  tittel: string;
}

export interface DriftsmeldingState {
  data: Driftsmelding[] | null | undefined;
  error: AxiosError | undefined;
  loading: boolean;
}

export interface DokumentMetadata {
    dokumentaar: number;
    dokumentnummer: number;
    embetenummer: number;
    rettsstiftelseskoder: string;
    tinglysingsdato: string;
}