import axios from "axios";
import { Fetcher } from "swr";

export const useSWRFetchers = () => {
  const fetcher: Fetcher = (url: string) =>
    axios.get(url).then((res) => res.data as unknown);

  const imageFetcher = async (url: string) =>
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => new Blob([res.data]))
      .then((res) => URL.createObjectURL(res));

  return {
    fetcher,
    imageFetcher,
  };
};
