import {
  HStack,
  IconButton,
  Radio,
  RadioGroup,
  Text,
  VStack,
} from "@kvib/react";
import React from "react";

import { Question } from "../../../../shared/bestillingTypes";

type Props = {
  name: string;
  isShowing?: boolean;
  value: string;
  question: Question;
};

const BestillingskjemaQuestion = ({
  name,
  isShowing = true,
  value,
  question,
}: Props) => {
  if (!isShowing) {
    return null;
  }

  return (
    <VStack alignItems="flex-start">
      <HStack>
        <Text fontSize="xl" fontWeight="bold">
          {question.title}
        </Text>
        <IconButton
          icon="info"
          colorScheme="blue"
          aria-label="icon-button"
          variant="tertiary"
        />
      </HStack>
      <RadioGroup name={name} width="400px" value={value}>
        {question.answers.map((answer) => (
          <HStack
            justifyContent="space-between"
            key={answer.value}
            paddingY={2}
          >
            <Radio value={answer.value}>{answer.label}</Radio>
            {answer.price && <Text> {answer.price} kr </Text>}
          </HStack>
        ))}
      </RadioGroup>
    </VStack>
  );
};

export default BestillingskjemaQuestion;
