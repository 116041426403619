import { KvibProvider } from "@kvib/react";
import App from "App";
import React from "react";
import ReactDOM from "react-dom/client";

import { BestillerProvider } from "./shared/context/BestillerContext";
import { BestillingerProvider } from "./shared/context/BestillingerContext";
import { FeatureFlagsProvider } from "./shared/context/FeatureFlagContext";
import { ReferanseProvider } from "./shared/context/ReferanseContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <KvibProvider>
      <BestillerProvider>
        <BestillingerProvider>
          <ReferanseProvider>
            <FeatureFlagsProvider>
              <App />
            </FeatureFlagsProvider>
          </ReferanseProvider>
        </BestillingerProvider>
      </BestillerProvider>
    </KvibProvider>
  </React.StrictMode>,
);
