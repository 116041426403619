import { Table, Tbody, Td, Tr } from "@kvib/react";
import React, { ReactElement } from "react";
import { TableCell } from "shared/components/TableCell";

interface Props {
  data: ReactElement[];
  headers: ReactElement[];
}

export const ToKolTabell: React.FC<Props> = ({ headers, data }) => {
  return (
    <Table variant="simple">
      <Tbody>
        {headers.map((header, key) => {
          return (
            <Tr key={key}>
              <Td px={1} width={{ base: "50%", sm: "auto" }}>
                {header}
              </Td>
              <TableCell>{data[key]}</TableCell>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};
