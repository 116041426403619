import {
  Button,
  HStack,
  Icon,
  IconButton,
  Image,
  Text,
  VStack,
} from "@kvib/react";
import React from "react";

const LeveringOgBetalingPrivat = () => {
  return (
    <VStack width="100%" paddingTop="32px" gap="32px">
      {/* Navn */}
      <VStack alignItems="flex-start" width="100%">
        <HStack width="100%" alignItems="center" justifyContent="space-between">
          <HStack alignItems="center" fontSize="2xl">
            <Icon icon="person" />
            <Text color="black">Hans Hansen</Text>
          </HStack>
          <Button variant="tertiary" fontSize="sm">
            Endre
          </Button>
        </HStack>
        <VStack alignItems="flex-start" gap={0}>
          <Text fontSize="lg">Bestillingsbekreftelsen sendes til</Text>
          <Text fontSize="lg">hans.hansen@hotmail.com</Text>
        </VStack>
        <Text fontSize="lg">+ 47 12 34 56 78</Text>
      </VStack>

      {/* Levering */}
      <VStack alignItems="flex-start" width="100%">
        <HStack width="100%" alignItems="center" justifyContent="space-between">
          <HStack alignItems="center" fontSize="2xl">
            <Icon icon="mail" />
            <Text color="black">Min Side</Text>
          </HStack>
          <Button variant="tertiary" fontSize="sm">
            Endre
          </Button>
        </HStack>
        <Text fontSize="lg">
          Digital levering på Min Side
          <IconButton icon="info" aria-label="icon-button" variant="tertiary" />
        </Text>
        <Text fontSize="lg">Forventet levering om 1 virkedag</Text>
      </VStack>

      {/* Betaling */}
      <VStack alignItems="flex-start" width="100%">
        <HStack width="100%" alignItems="center" justifyContent="space-between">
          <HStack alignItems="center" fontSize="2xl">
            <Icon icon="wallet" />
            <Text color="black">Betalingsmåte</Text>
          </HStack>
          <Button variant="tertiary" fontSize="sm">
            Endre
          </Button>
        </HStack>
        <HStack>
          <Image
            height="25px"
            src="https://vipps.no/media/images/vipps_logo_rgb.width-200.png"
          />
          <Text fontSize="lg">Betaling med Vipps</Text>
        </HStack>
      </VStack>
    </VStack>
  );
};

export default LeveringOgBetalingPrivat;
