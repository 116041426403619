import { Accordion, Box, Heading, Link, Stack, Text } from "@kvib/react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AccordionPanelWrapper } from "shared/components/AccordionPanelWrapper";

export const Faq: React.FC = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  useEffect(() => {
    document.title = "Se eiendom - Ofte stilte spørsmål";
  }, []);

  return (
    <Box maxWidth="container.md" margin="0 auto">
      <Link onClick={goBack}>
        <span
          className="material-symbols-outlined"
          style={{
            fontSize: "18px",
            marginRight: "4px",
            verticalAlign: "sub",
          }}
        >
          arrow_back
        </span>
        Gå tilbake
      </Link>
      <Heading as="h1" size="xl" my="6">
        Ofte stilte spørsmål
      </Heading>
      <Accordion allowToggle>
        <AccordionPanelWrapper title="Hvordan søker jeg?">
          <Stack spacing={3}>
            <Text>
              Du kan søke opp en eiendom ved å bruke eiendommens adresse eller
              matrikkelnummer. Søket starter når du har skrevet inn minst tre
              tegn. Er du usikker på skrivemåten skriver du så langt du er
              sikker. For å snevre inn antall treff, kan du skrive komma
              etterfulgt av stedsnavnet. Et matrikkelnummer består av tall i
              følgende rekkefølge: kommunenummer, gårdsnummer, bruksnummer,
              festenummer, seksjonsnummer.
            </Text>
            <Text>Matrikkelnummeret kan skrives på flere måter:</Text>
          </Stack>
          <Text>2100/22/339/0/4</Text>
          <Text>2100-22-339-0-4</Text>
          <Text>2100-22/339/0/4</Text>
          <Text>2100 22 339 0 4</Text>
        </AccordionPanelWrapper>
        <AccordionPanelWrapper title="Hvor mange ganger kan jeg åpne grunnboken?">
          <Text>
            Etter innlogging via ID-porten kan du mulighet til 20 visninger i
            grunnboken. Ønsker du grunnboksinformasjon for flere enn 20
            eiendommer, kan du logge inn på nytt.
          </Text>
        </AccordionPanelWrapper>
        <AccordionPanelWrapper title="Hvor finner jeg riktig kommune-, gårds- og bruksnummer?">
          <Stack spacing={3}>
            <Text>
              Hvis du ikke vet hva gårds- og bruksnummeret til eiendommen er,
              kan du søke på adressen. Dersom du ikke vet adressen, eller dersom
              eiendommen ikke har en adresse, men du vet hvor den er, kan du
              søke etter den i{" "}
              <Link href="https://norgeskart.no" isExternal>
                norgeskart
              </Link>
              . Når du trykker på eiendommen i kartet vil du på venstre side
              kunne velge &quot;se eiendomsinformasjon&quot; og &quot;vis mer
              informasjon&quot; som tar deg tilbake til Se eiendom.
            </Text>
            <Text>
              For å snevre inn antall treff, kan du skrive adressen, komma,
              etterfulgt av stedsnavnet. F.eks. Kartverksveien 21, Hønefoss.
            </Text>
            <Text>
              Dersom du ikke får treff med det kommunenavnet eller
              kommunenummeret du har søkt på, kan det være på grunn av
              gjennomførte kommunesammenslåinger. På Kartverkets{" "}
              <Link
                href="https://kartverket.no/til-lands/kommunereform/kommunesok"
                isExternal
              >
                kommunesøk
              </Link>{" "}
              kan du finne det nye kommunenavnet eller kommunenummeret. Her
              finner du også endringer som er gjort i gårdsnummerserier.
            </Text>
          </Stack>
        </AccordionPanelWrapper>
        <AccordionPanelWrapper title="Kan jeg se informasjon om andre eiendommer enn min egen?">
          <Text>
            Ja. Eiendomsdata fra grunnbok og matrikkel er, med få unntak,
            tilgjengelig for alle. Det vil si at du kan søke på hvilken som
            helst eiendom, og logge inn for å se en utskrift fra grunnboken.
            Informasjonen er offentlig tilgjengelig og grunneier eller andre
            parter blir ikke varslet dersom det blir gjort søk på eiendommen.
          </Text>
        </AccordionPanelWrapper>
        <AccordionPanelWrapper title="Hvorfor må jeg logge inn for å se en grunnboksutskrift?">
          <Stack spacing={3}>
            <Text>
              Du må logge inn fordi de tilgjengelige grunnboksutskriftene du
              finner på Se eiendom hovedsaklig skal være til privat bruk. Din
              innlogging, og hvilke grunnboksutskrifter du har åpnet, blir ikke
              lagret noe sted. Grunnboksutskriften du finner på Se eiendom er en
              visningstjeneste og det er ikke mulig å skrive ut. Dersom du
              trenger en bekreftet utskrift fra grunnboken må du bestille det
              gjennom{" "}
              <Link
                href="https://kartverket.no/eiendom/bestille-fra-grunnboken"
                isExternal
              >
                kartverket.no
              </Link>
              .
            </Text>
            <Text>
              Profesjonelle aktører som trenger større mengder data fra
              matrikkel og grunnbok kan inngå{" "}
              <Link
                href="https://kartverket.no/api-og-data/eiendomsdata"
                isExternal
              >
                avtale med Kartverket
              </Link>{" "}
              for å få tilgang til gratis data.
            </Text>
          </Stack>
        </AccordionPanelWrapper>
        <AccordionPanelWrapper title="Kan jeg søke på andeler borettslag?">
          <Text>
            Du kan søke på andelens adresse, eventuelt med bruksenhetsnummer
            (for eksempel: “Adresseveien 4A-H0101”). Det er ikke mulig å søke på
            organisasjonsnummeret til borettslaget og andelsnummeret. Du kan
            søke på matrikkelnummeret til borettslagets eiendom. Du vil få en
            liste over alle andeler i borettslaget. Her kan du se
            grunnboksutskriftene og hvilken adresse andelene er registrert med.
          </Text>
        </AccordionPanelWrapper>
        <AccordionPanelWrapper title="Hva er forskjellen på matrikkelen og grunnboken?">
          <Stack spacing={3}>
            <Text>
              {" "}
              <Link
                href="https://kartverket.no/eiendom/eiendomsgrenser/matrikkelen-norgeseiendomsregister"
                isExternal
              >
                Matrikkelen
              </Link>{" "}
              er Norges offisielle eiendomsregister, og inneholder faktiske
              opplysninger om eiendomsgrenser, areal, bygninger, boliger og
              adresser.
            </Text>
            <Text>
              {" "}
              <Link
                href="https://kartverket.no/eiendom/bestille-fra-grunnboken/hva-er-grunnboken"
                isExternal
              >
                Grunnboken
              </Link>{" "}
              inneholder tinglyste opplysninger om rettigheter til eiendommer,
              slik som registrerte eiere, panteretter og andre rettigheter. Et
              grunnboksblad for en eiendom inneholder også opplysninger om
              hvilken eiendom den er skilt ut fra, og om det er fradelt
              eiendommer fra den.
            </Text>
          </Stack>
        </AccordionPanelWrapper>
        <AccordionPanelWrapper title="Hva gjør jeg dersom jeg finner feil informasjon om en eiendom?">
          <Text>
            Dersom du mener informasjonen om grenser, adresser, bygninger eller
            lignende er feil, må du kontakte kommunen eiendommen ligger i for
            nærmere veiledning. Les mer i vår artikkel{" "}
            <Link
              href="https://kartverket.no/eiendom/eiendomsgrenser/feil-grenser-i-eiendomskartet"
              isExternal
            >
              feil grenser i eiendomskartet
            </Link>
            . Oppdager du feil i hva som er tinglyst på en eiendom, kan du
            kontakte Kartverket for nærmere veiledning.
          </Text>
        </AccordionPanelWrapper>
      </Accordion>
    </Box>
  );
};
