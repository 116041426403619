import {
  Button,
  Divider,
  HStack,
  Text,
  VisuallyHiddenInput,
  VStack,
} from "@kvib/react";
import React, { useEffect } from "react";
import { Form, Link, useLocation } from "react-router-dom";

import {PRIS_PR_PRODUKT} from "../../../shared/constants";
import { useBestillerContext } from "../../../shared/context/BestillerContext";
import { useBestillingerContext } from "../../../shared/context/BestillingerContext";
import { useReferanseContext } from "../../../shared/context/ReferanseContext";
import BestillDisplayObject from "../BestillDisplayObjects/BestillDisplayObject";

const Bestillingoversikt = () => {
  const { bestillinger, removeBestilling, clearBestillinger } =
    useBestillingerContext();
  const { referanse } = useReferanseContext();
  const { bestiller } = useBestillerContext();

  const location = useLocation();

  useEffect(() => {
    const bestillingerLocalStorage = localStorage.getItem("bestillinger");

    if (!bestillingerLocalStorage) {
      clearBestillinger();
    }
  }, [location, clearBestillinger]);

  const isEmpty = bestillinger.length === 0;
  const colorScheme = isEmpty ? "gray" : "green";

  const isBestillingsside = location.pathname === "/bestill";
  const isBetalingsside = location.pathname === "/bestill/levering-og-betaling";

  return (
    <VStack alignItems="flex-start">
      <VStack
        backgroundColor={`${colorScheme}.100`}
        padding={10}
        rounded="xl"
        gap="28px"
        alignItems="flex-start"
      >
        <Text fontSize="xl" fontWeight="bold">
          Bestillingsoversikt
        </Text>
        <Divider borderColor="gray" />
        <VStack width="300px" gap="28px">
          {bestillinger?.map((bestilling, index) => {
            return (
              <VStack key={index} alignItems="flex-start" width="100%">
                <BestillDisplayObject bestilling={bestilling} />
                <Button
                  onClick={() => removeBestilling(index)}
                  variant="tertiary"
                  leftIcon="delete"
                  paddingX="0"
                >
                  Fjern
                </Button>
              </VStack>
            );
          })}
          {isEmpty && (
            <Text color="gray">
              Bestillingsoversikten er tom. Søk opp dokumentnummer til
              dokumentene du ønsker å bestille.
            </Text>
          )}
        </VStack>
        <Divider borderColor="gray" />
        <HStack width="100%" justifyContent="space-between">
          <VStack alignItems="flex-start">
            <Text fontWeight="bold">Beløp</Text>
            <Text>Totalt å betale</Text>
          </VStack>
          <Text> {bestillinger.length * PRIS_PR_PRODUKT},- </Text>
        </HStack>
        <HStack>
          <Button
            colorScheme={colorScheme}
            isDisabled={isEmpty}
            onClick={clearBestillinger}
            variant="tertiary"
            leftIcon="delete"
          >
            Tøm handlekurven
          </Button>

          {isBestillingsside && (
            <Link to="/bestill/levering-og-betaling">
              <Button colorScheme={colorScheme} isDisabled={isEmpty}>
                Fortsett
              </Button>
            </Link>
          )}
          {isBetalingsside && (
            <Form method="post">
              <VisuallyHiddenInput
                name="bestillingerJson"
                defaultValue={JSON.stringify(bestillinger)}
              />
              <VisuallyHiddenInput name="referanse" defaultValue={referanse} />
              {bestiller && (
                <VisuallyHiddenInput
                  name="bestiller"
                  defaultValue={JSON.stringify(bestiller)}
                />
              )}
              <Button
                type="submit"
                colorScheme={colorScheme}
                isDisabled={isEmpty}
              >
                Betal
              </Button>
            </Form>
          )}
        </HStack>
      </VStack>
    </VStack>
  );
};

export default Bestillingoversikt;
