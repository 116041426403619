import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { Bestilling } from "../bestillingTypes";

const useBestillinger = () => {
  const [bestillinger, setBestillinger] = useState<Bestilling[]>([]);

  useEffect(() => {
    try {
      const bestillingerString = localStorage.getItem("bestillinger");
      if (bestillingerString) {
        setBestillinger(JSON.parse(bestillingerString));
      }
    } catch (error) {
      console.error("Failed to parse bestillinger from localStorage", error);
    }
  }, []);

  const setAndSaveBestillinger = (bestillinger: Bestilling[]) => {
    try {
      localStorage.setItem("bestillinger", JSON.stringify(bestillinger));
      setBestillinger(bestillinger);
    } catch (error) {
      console.error("Failed to save bestillinger to localStorage", error);
    }
  };

  const addBestilling = (bestilling: Bestilling) => {
    setAndSaveBestillinger([...bestillinger, bestilling]);
  };

  const removeBestilling = (index: number) => {
    const newBestillinger = [...bestillinger];
    newBestillinger.splice(index, 1);
    setAndSaveBestillinger(newBestillinger);
  };

  const clearBestillinger = () => {
    setAndSaveBestillinger([]);
  };

  const isBestillingInList = (bestilling: Bestilling) => {
    return bestillinger.some(
      (b) => JSON.stringify(b) === JSON.stringify(bestilling),
    );
  };

  return {
    bestillinger,
    addBestilling,
    removeBestilling,
    clearBestillinger,
    isBestillingInList,
  };
};

const BestillingerContext = createContext<{
  bestillinger: Bestilling[];
  addBestilling: (bestilling: Bestilling) => void;
  removeBestilling: (index: number) => void;
  clearBestillinger: () => void;
  isBestillingInList: (bestilling: Bestilling) => boolean;
}>({
  bestillinger: [],
  addBestilling: () => {},
  removeBestilling: () => {},
  clearBestillinger: () => {},
  isBestillingInList: () => true,
});

interface Props {
  children: ReactNode;
}
export const BestillingerProvider = ({ children }: Props) => {
  const {
    bestillinger,
    addBestilling,
    removeBestilling,
    clearBestillinger,
    isBestillingInList,
  } = useBestillinger();

  return (
    <BestillingerContext.Provider
      value={{
        bestillinger,
        addBestilling,
        removeBestilling,
        clearBestillinger,
        isBestillingInList,
      }}
    >
      {children}
    </BestillingerContext.Provider>
  );
};

export const useBestillingerContext = () => useContext(BestillingerContext);
