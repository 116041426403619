import { Box, HStack, Link, Text } from "@kvib/react";
import { Teig } from "api/apiDataTypes";
import { useTeiger } from "api/useTeiger";
import { getKartutsnittLenke } from "features/EiendomInfo/GrunnbokOgKart/Kartutsnitt";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  AccordionPanelWrapper,
  AccordionPanelWrapperError,
  AccordionPanelWrapperSkeltonLoader,
} from "shared/components/AccordionPanelWrapper";
import { InfoPopover } from "shared/components/InfoButton";
import PanelData from "shared/components/PanelData";
import { formatNumber } from "shared/utils/formatUtils";

export const TeigerPanel = () => {
  const ident = useParams();

  const { data: teiger, error, isLoading } = useTeiger(ident);
  const title = `Teiger (${teiger?.length ?? 0})`;

  const headers = [
    <HStack key="1">
      <b>Type</b>
    </HStack>,
    <HStack key="2">
      <b>Koordinater</b>
      <InfoPopover color="blue">
        <Text>
          Representasjonspunktet på teigen. Oppgitt i meter (nord, øst) EUREF89
          og UTM sone (EPSG kode).
        </Text>
      </InfoPopover>
    </HStack>,
    <HStack key="3">
      <b>Areal</b>
      <InfoPopover color="blue">
        <Text>
          Arealet beregnes på grunnlag av registrerte eiendomsgrenser og
          hjelpelinjer i matrikkelen. Det kan være mangler i
          grenseinformasjonen. Arealet kan være beregnet ut fra hjelpelinjer
          eller vannkanter. Dette vil fremgå av arealmerknadene.
        </Text>
      </InfoPopover>
    </HStack>,
    <HStack key="4">
      <b>Merknader</b>
    </HStack>,
  ];

  const rowElements = (teig: Teig) => [
    <Box key="1">
      {teig.type}
      {teig.isFlereMatrikkelenheter && <p>Teig med flere matrikkelenheter</p>}
      {teig.isUregistrertJordsameie && <p>Uregistrert jordsameie</p>}
      {teig.isUavklarteGrenser && <p>Uavklarte grenser</p>}
      {teig.medium && <p>{teig.medium}</p>}
    </Box>,
    <Box key="2">
      <Link
        href={`https://norgeskart.no/${getKartutsnittLenke([
          teig.koordinater.xpos ?? 0,
          teig.koordinater.ypos ?? 0,
        ])}`}
        isExternal
      >
        {teig.koordinater?.ypos} {teig.koordinater?.xpos}{" "}
        {`(${teig.koordinater?.epsgKode})`}
      </Link>
    </Box>,
    <Box key="3">
      {formatNumber(teig.areal)} m<sup>2</sup>
    </Box>,
    <Box key="4">{teig.arealmerknad}</Box>,
  ];

  const rows = useMemo(() => {
    return (
      teiger?.map((teig) => ({
        title: teig.type,
        subtitle: `${formatNumber(teig.areal)}m²`,
        data: rowElements(teig),
      })) ?? []
    );
  }, [teiger]);

  if (isLoading) {
    return <AccordionPanelWrapperSkeltonLoader title="Teiger" />;
  }

  if (error) {
    return (
      <AccordionPanelWrapperError
        title="Teiger"
        errorText="Kunne ikke hente teiger"
      />
    );
  }

  if (teiger?.length === 0) {
    return (
      <AccordionPanelWrapper title={title}>
        Fant ingen registrerte teiger.
      </AccordionPanelWrapper>
    );
  }

  return (
    <AccordionPanelWrapper title={title}>
      <PanelData caption="Teiger" headers={headers} rows={rows} />
    </AccordionPanelWrapper>
  );
};
