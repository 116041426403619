import { HStack, Text, VStack } from "@kvib/react";

import { GrunnboksutskriftBestillingBorettslag } from "../../../shared/bestillingTypes";
import { PRIS_PR_PRODUKT } from "../../../shared/constants";

type Props = {
  grunnboksutskriftBestillingBorettslag: GrunnboksutskriftBestillingBorettslag;
};

const BestillGrunnbokBorettslagDisplayObject = ({
  grunnboksutskriftBestillingBorettslag,
}: Props) => {
  const { borettslagandelIdent, nynorsk, historisk } =
    grunnboksutskriftBestillingBorettslag;

  return (
    <HStack width="100%" justifyContent="space-between">
      <VStack alignItems="flex-start">
        <Text fontWeight="bold">
          {borettslagandelIdent.borettslagnummer}-
          {borettslagandelIdent.andelsnummer}
        </Text>
        <Text> Borettslagsandel </Text>
        <Text> {historisk ? "Historisk" : "Aktiv"} grunnboksutskrift </Text>
        <Text> Språk: {nynorsk ? "Nynorsk" : "Bokmål"} </Text>
      </VStack>
      <Text>{PRIS_PR_PRODUKT},-</Text>
    </HStack>
  );
};

export default BestillGrunnbokBorettslagDisplayObject;
