import {
  Flex,
  Link,
  Table,
  TableContainer,
  Tbody,
  Text,
  Tr,
} from "@kvib/react";
import { useBasicInfo } from "api/useBasicInfo";
import { useEiendomInfo } from "api/useEiendomInfo";
import { getKartutsnittLenke } from "features/EiendomInfo/GrunnbokOgKart/Kartutsnitt";
import { useParams } from "react-router-dom";
import { AccordionPanelWrapper } from "shared/components/AccordionPanelWrapper";
import { InfoPopover } from "shared/components/InfoButton";
import { AccordionTableCell } from "shared/components/TableCell";
import { capitalizeFirstChar, formatNumber } from "shared/utils/formatUtils";

export const EiendomPanel = () => {
  const ident = useParams();
  const { data: basicInfo } = useBasicInfo(ident);
  const { data: eiendomInfo } = useEiendomInfo(ident);

  return (
    <AccordionPanelWrapper title="Eiendom">
      <Flex py={5} flexWrap="wrap">
        <TableContainer
          width={{ base: "100%", md: "50%" }}
          whiteSpace={{ base: "normal", md: "nowrap" }}
          paddingRight={{ base: 0, md: "10" }}
        >
          <Table variant="simple" whiteSpace={{ base: "normal", md: "nowrap" }}>
            <Tbody>
              <Tr>
                <AccordionTableCell>
                  <b>Type</b>
                  <InfoPopover color="blue">
                    <Text>
                      Type matrikkelenhet (grunneiendom, festegrunn, seksjon,
                      anleggseiendom, jordsameie eller annen matrikkelenhet).
                    </Text>
                  </InfoPopover>
                </AccordionTableCell>
                <AccordionTableCell>{eiendomInfo?.type} </AccordionTableCell>
              </Tr>
              {eiendomInfo?.undertype && (
                <Tr>
                  <AccordionTableCell>
                    <b>Undertype</b>
                  </AccordionTableCell>
                  <AccordionTableCell>
                    {eiendomInfo.undertype}
                  </AccordionTableCell>
                </Tr>
              )}
              <Tr>
                <AccordionTableCell>
                  <b>Kommune</b>
                </AccordionTableCell>
                <AccordionTableCell>
                  {eiendomInfo?.matrikkelenhetident?.kommunenr}{" "}
                  {eiendomInfo?.kommunenavn}
                </AccordionTableCell>
              </Tr>
              <Tr>
                <AccordionTableCell>
                  <b>Gårdsnummer</b>
                </AccordionTableCell>
                <AccordionTableCell>
                  {basicInfo?.matrikkelIdent?.gaardsnr}
                </AccordionTableCell>
              </Tr>
              <Tr>
                <AccordionTableCell>
                  <b>Bruksnummer</b>
                </AccordionTableCell>
                <AccordionTableCell>
                  {basicInfo?.matrikkelIdent?.bruksnr}
                </AccordionTableCell>
              </Tr>
              <Tr>
                <AccordionTableCell>
                  <b>Festegrunner</b>
                  <InfoPopover color="blue">
                    <Text>
                      Vil være ja hvis deler av eiendommen er registrert som
                      festegrunn. Mer informasjon finner du på{" "}
                      <Link
                        href="https://kartverket.no/eiendom/tomtefeste"
                        isExternal
                      >
                        kartverket.no
                      </Link>{" "}
                    </Text>
                  </InfoPopover>
                </AccordionTableCell>
                <AccordionTableCell>
                  {eiendomInfo?.isFestegrunner ? "Ja" : "Nei"}
                </AccordionTableCell>
              </Tr>
              <Tr>
                <AccordionTableCell>
                  <b>Seksjonert</b>
                  <InfoPopover color="blue">
                    <Text>
                      Forteller om eiendommen/festegrunnen er delt i
                      eierseksjoner.
                    </Text>
                  </InfoPopover>
                </AccordionTableCell>
                <AccordionTableCell>
                  {eiendomInfo?.isSeksjonert ? "Ja" : "Nei"}
                </AccordionTableCell>
              </Tr>
              {!!basicInfo?.matrikkelIdent?.festenr && (
                <Tr>
                  <AccordionTableCell>
                    <b>Festenummer</b>
                  </AccordionTableCell>
                  <AccordionTableCell>
                    {basicInfo.matrikkelIdent.festenr}
                  </AccordionTableCell>
                </Tr>
              )}
              {!!basicInfo?.matrikkelIdent?.seksjonsnr && (
                <Tr>
                  <AccordionTableCell>
                    <b>Seksjonsnummer</b>
                  </AccordionTableCell>
                  <AccordionTableCell>
                    {basicInfo.matrikkelIdent.seksjonsnr}
                  </AccordionTableCell>
                </Tr>
              )}
              <Tr>
                <AccordionTableCell>
                  <b>Bruksnavn</b>
                  <InfoPopover color="blue">
                    <Text>Eiendommens navn etter vedtak.</Text>
                  </InfoPopover>
                </AccordionTableCell>
                <AccordionTableCell>
                  {eiendomInfo?.bruksnavn &&
                    capitalizeFirstChar(eiendomInfo.bruksnavn)}
                </AccordionTableCell>
              </Tr>
              <Tr>
                <AccordionTableCell>
                  <b>Tinglyst</b>
                  <InfoPopover color="blue">
                    <Text>
                      Hvis nei, er eiendommen ikke tinglyst i grunnboken.
                    </Text>
                  </InfoPopover>
                </AccordionTableCell>
                <AccordionTableCell>
                  {eiendomInfo?.isTinglyst ? "Ja" : "Nei"}
                </AccordionTableCell>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
        <TableContainer
          width={{ base: "100%", md: "50%" }}
          whiteSpace={{ base: "normal", md: "nowrap" }}
          paddingRight={{ base: 0, md: "10" }}
        >
          <Table variant="simple" whiteSpace={{ base: "normal", md: "nowrap" }}>
            <Tbody>
              <Tr>
                <AccordionTableCell>
                  <b>Koordinater</b>
                  <InfoPopover color="blue">
                    <Text>
                      Koordinater for representasjonspunktet til hovedteigen
                      (hoveddelen av eiendommen). Oppgitt i meter (nord, øst)
                      EUREF89 og UTM sone (EPSG kode).
                    </Text>
                  </InfoPopover>
                </AccordionTableCell>
                <AccordionTableCell>
                  {eiendomInfo?.koordinater?.xpos &&
                  eiendomInfo?.koordinater?.ypos ? (
                    <Link
                      href={`https://norgeskart.no/${getKartutsnittLenke([
                        eiendomInfo.koordinater.xpos,
                        eiendomInfo.koordinater.ypos,
                      ])}`}
                      isExternal
                    >
                      {`${eiendomInfo.koordinater.ypos} 
                        
                        ${eiendomInfo.koordinater.xpos} 
                        
                        (${eiendomInfo.koordinater.epsgKode})`}
                    </Link>
                  ) : (
                    "Har ikke registrerte teiger"
                  )}
                </AccordionTableCell>
              </Tr>
              <Tr>
                <AccordionTableCell>
                  <b>Areal</b>
                  <InfoPopover color="blue">
                    <Text>
                      Arealet beregnes på grunnlag av registrerte
                      eiendomsgrenser i matrikkelen. Det kan være mangler i
                      grenseinformasjonen. Arealet kan være beregnet ut fra
                      hjelpelinjer eller vannkanter. Dette vil fremgå av
                      arealmerknadene. Ta kontakt med kommunen eller Kartverket
                      dersom du mener eiendomsgrenser eller areal er feil.
                    </Text>
                  </InfoPopover>
                </AccordionTableCell>
                <AccordionTableCell>
                  <Text>
                    {formatNumber(eiendomInfo?.oppgittAreal ?? 0)} m
                    <Text as="sup">2</Text>
                  </Text>
                </AccordionTableCell>
              </Tr>
              <Tr>
                <AccordionTableCell>
                  <b>Historisk oppgitt areal</b>
                  <InfoPopover color="blue">
                    <Text>
                      Manuelt registrert areal som framkommer av eldre registre.{" "}
                    </Text>
                  </InfoPopover>
                </AccordionTableCell>
                <AccordionTableCell>
                  <Text>
                    {formatNumber(eiendomInfo?.historiskOppgittAreal ?? 0)} m
                    <Text as="sup">2</Text>
                  </Text>
                </AccordionTableCell>
              </Tr>
              <Tr>
                <AccordionTableCell>
                  <b>Arealmerknad</b>
                  <InfoPopover color="blue">
                    <Text>
                      Merknader om hjelpelinjer, vannkant eller vegkant. Dersom
                      en eiendom har merknader er arealverdien usikker.
                    </Text>
                  </InfoPopover>
                </AccordionTableCell>
                <AccordionTableCell>
                  {eiendomInfo?.arealmerknad}
                </AccordionTableCell>
              </Tr>
              <Tr>
                <AccordionTableCell>
                  <b>Under sammenslåing</b>
                </AccordionTableCell>
                <AccordionTableCell>
                  {eiendomInfo?.underSammenslaaing}
                </AccordionTableCell>
              </Tr>
              <Tr>
                <AccordionTableCell>
                  <b>Grunnforurensing</b>
                  <InfoPopover color="blue">
                    <Text>
                      Kontakt{" "}
                      <Link href="http://www.miljodirektoratet.no/" isExternal>
                        Miljødirektoratet
                      </Link>{" "}
                      for nærmere opplysninger.
                    </Text>
                  </InfoPopover>
                </AccordionTableCell>
                <AccordionTableCell>
                  {eiendomInfo?.isGrunnforurensning ? "Ja" : "Nei"}
                </AccordionTableCell>
              </Tr>
              <Tr>
                <AccordionTableCell>
                  <b>Kulturminner</b>
                  <InfoPopover color="blue">
                    <Text>
                      Dersom eiendommen har kulturminner, finner du detaljer
                      nedenfor. Kontakt{" "}
                      <Link href="https://www.riksantikvaren.no/" isExternal>
                        Riksantikvaren
                      </Link>{" "}
                      for nærmere opplysninger.
                    </Text>
                  </InfoPopover>
                </AccordionTableCell>
                <AccordionTableCell>
                  {eiendomInfo?.isKulturminner ? "Ja" : "Nei"}
                </AccordionTableCell>
              </Tr>
              <Tr>
                <AccordionTableCell>
                  <b>MUF</b>
                  <InfoPopover color="blue">
                    <Text>
                      Matrikulering uten fullført oppmålingsforretning. Hvis{" "}
                      <i>ja</i> er det foreløpige grenser som er registrert i
                      matrikkelen. Mer informasjon finner du på{" "}
                      <Link
                        href="https://kartverket.no/eiendom/lokal-matrikkelmyndighet/matrikkelenheter-med-flagg"
                        isExternal
                      >
                        kartverket.no
                      </Link>
                    </Text>
                  </InfoPopover>
                </AccordionTableCell>
                <AccordionTableCell>
                  {eiendomInfo?.isOppmalingIkkeFullfort ? "Ja" : "Nei"}
                </AccordionTableCell>
              </Tr>
              <Tr>
                <AccordionTableCell>
                  <b>Grunnerverv</b>
                  <InfoPopover color="blue">
                    <Text>
                      Avtale eller vedtak om erverv av grunn til offentlig veg
                      eller jernbane. Når et grunnerverv er ferdig og ført i
                      matrikkelen vil status endres fra ja til nei. Mer
                      informasjon finner du på{" "}
                      <Link
                        href="https://kartverket.no/eiendom/lokal-matrikkelmyndighet/matrikkelenheter-med-flagg"
                        isExternal
                      >
                        kartverket.no
                      </Link>
                    </Text>
                  </InfoPopover>
                </AccordionTableCell>
                <AccordionTableCell>
                  {eiendomInfo?.isHarRegistrertGrunnerverv ? "Ja" : "Nei"}
                </AccordionTableCell>
              </Tr>
              <Tr>
                <AccordionTableCell>
                  <b>Jordskifte krevd</b>
                  <InfoPopover color="blue">
                    <Text>
                      Eiendommer som er del av en pågående jordskiftesak. Når en
                      jordskiftesak er ferdig og ført i matrikkelen, eller den
                      tas ut av saken, vil status endres fra ja til nei. Mer
                      informasjon finner du på{" "}
                      <Link
                        href="https://kartverket.no/eiendom/lokal-matrikkelmyndighet/matrikkelenheter-med-flagg"
                        isExternal
                      >
                        kartverket.no
                      </Link>
                    </Text>
                  </InfoPopover>
                </AccordionTableCell>
                <AccordionTableCell>
                  {eiendomInfo?.isJordskifteKrevd ? "Ja" : "Nei"}
                </AccordionTableCell>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
    </AccordionPanelWrapper>
  );
};
