import {
  ConfigureAuthFlowProps,
  useConfigureAuthFlow,
} from "@kartverket/frontend-aut-lib";
import { OnReturnFromEndSession } from "@kartverket/frontend-aut-lib/dist/OnReturnFromEndSession";
import { OnReturnFromLogonDialog } from "@kartverket/frontend-aut-lib/dist/OnReturnFromLogonDialog";
import { useSWRFetchers } from "api/useSWRFetchers";
import { EiendomInfo } from "features/EiendomInfo/EiendomInfo";
import { Faq } from "features/Faq/Faq";
import NotFound from "features/NotFound/NotFound";
import { Search } from "features/Search/Search";
import React from "react";
import {
  createBrowserRouter,
  Outlet,
  redirect,
  RouterProvider,
} from "react-router-dom";
import { AppLayout } from "shared/components/AppLayout";
import { SWRConfig } from "swr";

import {
  OrganisasjonBestiller,
  PrivatpersonBestiller,
} from "./api/bestillApiDataTypes";
import { sendBestilling } from "./api/sendBestilling";
import Bestill from "./features/Bestill/Bestill";
import Bestillingskjema from "./features/Bestill/Bestillingskjema/Bestillingskjema";
import BestillKvittering from "./features/Bestill/BestillKvittering/BestillKvittering";
import BestillLeveringOgBetaling from "./features/Bestill/BestillLeveringOgBetaling/BestillLeveringOgBetaling";
import { Bestilling } from "./shared/bestillingTypes";
import { fetchFeatureFlags } from "./shared/utils/fetchFeatureFlags";

const Root = () => {
  document.documentElement.lang = "nb-NO";
  const { fetcher } = useSWRFetchers();

  return (
    <SWRConfig value={{ fetcher }}>
      <Outlet />
    </SWRConfig>
  );
};

export default function App() {
  const authFlowProps: ConfigureAuthFlowProps = {
    systemId: "se-eiendom",
    fallbackUrl: "/",
    afterUserLogoutRedirect: "/",
  };

  useConfigureAuthFlow(authFlowProps);

  const router = createBrowserRouter([
    {
      path: "*",
      element: <Root />,
      children: [
        {
          path: authFlowProps.authRedirectUri || "authenticated",
          element: <OnReturnFromLogonDialog />,
        },
        {
          path: authFlowProps.postLogoutUri || "logout",
          element: <OnReturnFromEndSession />,
        },
        {
          index: true,
          element: (
            <AppLayout>
              <Search />
            </AppLayout>
          ),
        },
        {
          path: "eiendom/:kommunenr/:gaardsnr/:bruksnr/:festenr/:seksjonsnr",
          element: (
            <AppLayout>
              <EiendomInfo />
            </AppLayout>
          ),
        },
        {
          path: "eiendom/:kommunenr/:gaardsnr/:bruksnr/:festenr",
          element: (
            <AppLayout>
              <EiendomInfo />
            </AppLayout>
          ),
        },
        {
          path: "eiendom/:kommunenr/:gaardsnr/:bruksnr",
          element: (
            <AppLayout>
              <EiendomInfo />
            </AppLayout>
          ),
        },
        {
          path: "faq",
          element: (
            <AppLayout>
              <Faq />
            </AppLayout>
          ),
        },
        {
          path: "bestill",
          element: (
            <AppLayout>
              <Bestill>
                <Outlet />
              </Bestill>
            </AppLayout>
          ),
          loader: async () => {
            const featureFlags = await fetchFeatureFlags();

            if (!featureFlags.enableBestilling) {
              return redirect("/");
            }

            return {};
          },
          children: [
            {
              index: true,
              element: <Bestillingskjema />,
            },
            {
              path: "levering-og-betaling",
              element: <BestillLeveringOgBetaling />,
              action: async ({ params: _, request }) => {
                const formData = await request.formData();
                const bestillingerJsonString = formData.get(
                  "bestillingerJson",
                ) as string;
                const bestillerJsonString = formData.get("bestiller") as string;

                const bestillinger: Bestilling[] = JSON.parse(
                  bestillingerJsonString,
                );
                const bestiller: PrivatpersonBestiller | OrganisasjonBestiller =
                  JSON.parse(bestillerJsonString);
                const referanse = (formData.get("referanse") as string) || "";

                const response = await sendBestilling(
                  bestillinger,
                  bestiller,
                  referanse,
                );

                if (!response) {
                  throw new Error("Kunne ikke sende bestilling");
                }

                localStorage.removeItem("bestillinger");

                return redirect("/bestill/kvittering");
              },
            },
            {
              path: "kvittering",
              element: <BestillKvittering />,
            },
          ],
        },
        {
          path: "*",
          element: (
            <AppLayout>
              <NotFound />
            </AppLayout>
          ),
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}
