import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  AlertTitle,
  HStack,
  Skeleton,
  Text,
} from "@kvib/react";
import React, { ReactNode } from "react";

type WrapperProps = {
  children?: ReactNode;
  title: string | ReactNode;
};

const Wrapper = ({ children, title }: WrapperProps) => (
  <section>
    <AccordionItem borderTop="solid 1px" borderBottom="none">
      <AccordionButton fontSize="lg" px={0}>
        <Text as="h2" fontWeight="bold" flex="1" textAlign="left" my={2}>
          {title}
        </Text>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel px={0}>{children}</AccordionPanel>
    </AccordionItem>
  </section>
);

type Props = {
  children: React.ReactNode;
  title: string | React.ReactNode;
};

export const AccordionPanelWrapper = ({ children, title }: Props) => (
  <Wrapper title={title}>{children}</Wrapper>
);

export const AccordionPanelWrapperSkeltonLoader = ({
  title,
}: {
  title: string;
}) => {
  return (
    <Wrapper
      title={
        <HStack>
          <span>{title}</span>
          <Skeleton height="25px" width="50px" />
        </HStack>
      }
    >
      {null}
    </Wrapper>
  );
};

type ErrorProps = {
  errorText: string;
  title: string;
};

export const AccordionPanelWrapperError = ({
  title,
  errorText,
}: ErrorProps) => {
  return (
    <AccordionPanelWrapper
      title={
        <HStack>
          <Text>{title}</Text>
          <span
            style={{ color: "var(--kvib-colors-red-500)" }}
            className="material-symbols-outlined"
          >
            warning
          </span>
        </HStack>
      }
    >
      <Alert status="error" marginBottom={5}>
        <AlertIcon />
        <AlertTitle>{errorText}</AlertTitle>
      </Alert>
    </AccordionPanelWrapper>
  );
};
