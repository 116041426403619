export enum BestillerType {
  PRIVATPERSON = "PRIVATPERSON",
  ORGANISASJON = "ORGANISASJON",
}

export interface DokumentIdent {
  dokumentaar: number;
  dokumentnummer: number;
  embetenummer: number;
}

export interface MatrikkelIdent {
  kommunenummer: string;
  gaardsnummer: number;
  bruksnummer: number;
  festenummer: number;
  seksjonsnummer: number;
}

export interface BorettslagIdent {
  borettslagnummer: string;
  andelsnummer: number;
}

export interface Bestiller {
  type: BestillerType;
}

export interface PrivatpersonBestiller extends Bestiller {
  type: BestillerType.PRIVATPERSON;
  fodselsnummer?: string;
  navn: string;
  adresse: string;
  postnummer: string;
}

export interface OrganisasjonBestiller extends Bestiller {
  type: BestillerType.ORGANISASJON;
  organisasjonsnummer: string;
}

export type BestillerUnion = PrivatpersonBestiller | OrganisasjonBestiller;

export interface GrunnbokInternRequest {
  referanse: string;
  bestiller: BestillerUnion;
  nynorsk: boolean;
  historisk: boolean;
}

export interface GrunnbokInternFastEiendomBestillingRequest
  extends GrunnbokInternRequest {
  matrikkelIdent: MatrikkelIdent;
}

export interface GrunnbokInternBorettslagBestillingRequest
  extends GrunnbokInternRequest {
  borettslagandelIdent: BorettslagIdent;
}

export interface PantebokInternBestillingRequest {
  dokumentIdent: DokumentIdent;
  referanse: string;
}

export interface InternBestillingRequest {
  bestillingForAlleBestiller: BestillerUnion;
  fastEiendomBestillinger: GrunnbokInternFastEiendomBestillingRequest[];
  borettslagBestillinger: GrunnbokInternBorettslagBestillingRequest[];
  pantebokBestillinger: PantebokInternBestillingRequest[];
}
