import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Divider,
  HStack,
  Input,
  Text,
  VStack,
} from "@kvib/react";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

import useDokumentMetadata from "../../../../api/useDokumentMetadata";
import {
  BestillingType,
  TinglystDokumentBestilling,
} from "../../../../shared/bestillingTypes";
import { PRIS_PR_PRODUKT } from "../../../../shared/constants";
import { useBestillingerContext } from "../../../../shared/context/BestillingerContext";
import {
  BASE_10_RADIX,
  formatNorwegianDate,
} from "../../../../shared/utils/formatUtils";

const BestillingskjemaPantebok = () => {
  const { isBestillingInList, addBestilling } = useBestillingerContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const [dokumentIdent, setDokumentIdent] = useState(
    searchParams.get("dokumentIdent") ?? "",
  );

  const permittedSeparatorsRegex = /[.,/ -]/;

  const parsedDokumentIdent: [number, number, number] | null = (() => {
    const parts = dokumentIdent.split(permittedSeparatorsRegex);
    if (parts.length !== 3) {
      return null;
    }

    const [dokumentAarStr, dokumentNrStr, embeteStr] = parts;

    const dokumentAar = parseInt(dokumentAarStr, BASE_10_RADIX);
    const dokumentNr = parseInt(dokumentNrStr, BASE_10_RADIX);
    const embete = parseInt(embeteStr, BASE_10_RADIX);

    if (isNaN(dokumentAar) || isNaN(dokumentNr) || isNaN(embete)) {
      return null;
    }

    return [dokumentAar, dokumentNr, embete];
  })();

  const isValid = (() => {
    const regexPattern = new RegExp(
      `^\\d{4}${permittedSeparatorsRegex.source}\\d{1,10}${permittedSeparatorsRegex.source}\\d{2,3}$`,
    );
    const isValidFormat = regexPattern.test(dokumentIdent);

    if (!isValidFormat) {
      return false;
    }

    if (!parsedDokumentIdent) {
      return false;
    }

    const dokumentAar = parsedDokumentIdent[0];
    return dokumentAar <= new Date().getFullYear();
  })();

  const dokumentData = useDokumentMetadata(
    parsedDokumentIdent ? parsedDokumentIdent[0] : 0,
    parsedDokumentIdent ? parsedDokumentIdent[1] : 0,
    parsedDokumentIdent ? parsedDokumentIdent[2] : 0,
  );

  const currentBestilling: TinglystDokumentBestilling | undefined = (() => {
    if (!isValid) {
      return undefined;
    }

    const [dokumentaarStr, dokumentnummerStr, embetenummerStr] =
      dokumentIdent.split(permittedSeparatorsRegex);
    const dokumentaar = parseInt(dokumentaarStr, BASE_10_RADIX);
    const dokumentnummer = parseInt(dokumentnummerStr, BASE_10_RADIX);
    const embetenummer = parseInt(embetenummerStr, BASE_10_RADIX);

    return {
      bestillingType: BestillingType.PANTEBOK,
      dokumentIdent: {
        dokumentaar,
        dokumentnummer,
        embetenummer,
      },
      tinglystDato: dokumentData?.tinglysingsdato,
    } as TinglystDokumentBestilling;
  })();

  const isAlreadyInList =
    currentBestilling && isBestillingInList(currentBestilling);

  const alertBox = (status: "error" | "warning" | "info", message: string) => {
    return (
      <Alert status={status} width="100%">
        <AlertIcon />
        <AlertTitle>{message}</AlertTitle>
      </Alert>
    );
  };

  const handleAddBestilling = () => {
    if (currentBestilling && !isAlreadyInList) {
      addBestilling(currentBestilling);
    }

    setSearchParams({});
  };

  return (
    <>
      <VStack alignItems="flex-start">
        <Text fontSize="xl" fontWeight="bold">
          Dokumentnummer
        </Text>
        <Input
          name="dokumentIdent"
          value={dokumentIdent}
          onChange={(e) => setDokumentIdent(e.target.value)}
          placeholder='Eksempel "2023/42259/200"'
          isInvalid={!isValid && dokumentIdent !== ""}
          width={300}
          size="lg"
        />
      </VStack>
      {dokumentIdent && (
        <>
          <Divider />
          <VStack alignItems="flex-start" gap={10}>
            {!isValid &&
              dokumentIdent !== "" &&
              alertBox("error", "Dokumentnummeret er ugyldig.")}
            {isValid && dokumentData && (
              <>
                <HStack width="100%" gap={100}>
                  <VStack alignItems="flex-start">
                    <Text fontWeight="bold">
                      {dokumentData.dokumentaar}/{dokumentData.dokumentnummer}/
                      {dokumentData.embetenummer}
                    </Text>
                    <Text>
                      Tinglyst:{" "}
                      {formatNorwegianDate(dokumentData.tinglysingsdato)}
                    </Text>
                  </VStack>
                  <Text>{PRIS_PR_PRODUKT},-</Text>
                  <Button
                    onClick={handleAddBestilling}
                    isDisabled={isAlreadyInList}
                    leftIcon="add"
                    variant="secondary"
                  >
                    Legg til
                  </Button>
                </HStack>
                {isAlreadyInList &&
                  alertBox(
                    "info",
                    "Dokumentet er allerede lagt til i bestillingen.",
                  )}
              </>
            )}
            {isValid &&
              !dokumentData &&
              alertBox("info", "Dokumentet ble ikke funnet.")}
          </VStack>
        </>
      )}
    </>
  );
};

export default BestillingskjemaPantebok;
