import { AxiosError } from "axios";
import useSWRImmutable from "swr/immutable";

import { PartialIdent, ServerState } from "./apiDataTypes";
import { getBaseUrl } from "./config";
import { useEiendomInfo } from "./useEiendomInfo";

export function useGetByEiendomInfo<T>(
  ident: PartialIdent,
  path: string,
): ServerState<T> {
  const { data: eiendomInfo } = useEiendomInfo(ident);
  const url = `${getBaseUrl()}${path}`;

  const { data, error } = useSWRImmutable<T, AxiosError>(() => {
    if (eiendomInfo?.matrikkelenhetId) {
      return `${url}${eiendomInfo.matrikkelenhetId}`;
    } else {
      return null;
    }
  });

  if (url == undefined) {
    return {
      data: undefined,
      error: new Error("Ugyldig matrikkelenhetident"),
      isLoading: false,
    };
  } else if (error != undefined) {
    return { data: undefined, error, isLoading: false };
  } else if (data != undefined) {
    return { data, error: undefined, isLoading: false };
  } else {
    return { data: undefined, error, isLoading: true };
  }
}
