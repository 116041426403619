import { Accordion } from "@kvib/react";
import React, { ReactElement } from "react";
import { AccordionCard } from "shared/components/AccordionCard";
import { ToKolTabell } from "shared/components/ToKolTabell";

interface Props {
  headers: ReactElement[];
  rows: { data: ReactElement[]; subtitle?: string; title: string }[];
}

const AccordionTables: React.FC<Props> = ({ headers, rows }) => {
  return (
    <>
      {rows?.length === 1 ? (
        <ToKolTabell headers={headers} data={rows[0].data} />
      ) : (
        <Accordion allowToggle>
          <ol>
            {rows.map((rad) => {
              return (
                <li key={`${rad.title}${rad.subtitle}`}>
                  <AccordionCard title={rad.title} subtitle={rad.subtitle}>
                    <ToKolTabell headers={headers} data={rad.data} />
                  </AccordionCard>
                </li>
              );
            })}
          </ol>
        </Accordion>
      )}
    </>
  );
};

export default AccordionTables;
