import {
  Box,
  Button,
  Card,
  CardBody,
  Container,
  Flex,
  Heading,
  HStack,
  Input,
  Link,
  Spinner,
  Stack,
  Text,
  useMediaQuery,
  VStack,
} from "@kvib/react";
import { EnkelAdresse } from "api/apiDataTypes";
import { useGetEiendommer } from "api/useGetEiendommer";
import { MatrikkelEnhet } from "features/Search/MatrikkelEnhet";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { GreenContainer } from "shared/components/GreenContainer";
import { InfoPopover } from "shared/components/InfoButton";
import EiendomIcon from "shared/icons/EiendomIcon";

import { Feilmelding } from "../../shared/components/Feilmelding";
import { useFeatureFlagsContext } from "../../shared/context/FeatureFlagContext";

const MAX_SEARCH_RESULT_COUNT = 20;
const MIN_SEARCH_CHAR_COUNT = 2;
const TIMEOUT_DURATION_BEFORE_SEARCH = 350;

export const Search = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState(searchParams.get("query") ?? "");
  const featureFlags = useFeatureFlagsContext();
  const { data, error, isLoading, setFetchQuery } = useGetEiendommer(query);
  const [isAbove550px] = useMediaQuery("(min-width: 550px)");

  useEffect(() => {
    document.title = "Se eiendom - Søk og finn info om en eiendom";
  }, []);

  useEffect(() => {
    if (query.length > MIN_SEARCH_CHAR_COUNT) {
      const doSearch = setTimeout(() => {
        setFetchQuery(query);
        setSearchParams({ query });
      }, TIMEOUT_DURATION_BEFORE_SEARCH);
      return () => clearTimeout(doSearch);
    } else {
      setFetchQuery("");
    }
  }, [query, setFetchQuery, setSearchParams]);

  const renderResults = () => {
    if (error) {
      switch (error.response?.status) {
        case 429:
          return (
            <Feilmelding title="For mange søk">
              <Text>
                Du eller noen på ditt nettverk har gjort for mange søk.
                Vennligst vent litt før du prøver igjen. Ta kontakt med
                kundesenteret dersom dette er et vedvarende problem.
              </Text>
            </Feilmelding>
          );
        case 400:
          return (
            <Feilmelding title="Ugyldig søk">
              <Text>Søket ditt er ugyldig. Vennligst prøv igjen.</Text>
            </Feilmelding>
          );
        default:
          return (
            <Feilmelding title="En uventet feil oppsto">
              <Text>
                Se eiendom har problemer med å hente søkeresultatet, prøv igjen
                senere.
              </Text>
            </Feilmelding>
          );
      }
    }

    if (isLoading) {
      return <Spinner />;
    }

    if (!data) {
      return;
    }

    if (data.matrikkelenheter?.length !== 0) {
      return (
        <>
          <Heading as="h3" size="md" mb="2">
            Søkeresultat
          </Heading>
          {data.matrikkelenheter.length == MAX_SEARCH_RESULT_COUNT && (
            <Text fontSize="md" fontWeight="bold" marginBottom="2">
              Viser de {MAX_SEARCH_RESULT_COUNT} første søketreffene, skriv mer
              for å begrense søket
            </Text>
          )}
          {data.matrikkelenheter.length < MAX_SEARCH_RESULT_COUNT && (
            <Text fontSize="md" fontWeight="bold" marginBottom="2">
              Viser{" "}
              {data.matrikkelenheter.length === 1
                ? "ett"
                : data.matrikkelenheter.length}{" "}
              søketreff
            </Text>
          )}
          {data.matrikkelenheter.length < MAX_SEARCH_RESULT_COUNT &&
            data.flereResultater && (
              <Text fontSize="md" fontWeight="bold" marginBottom="2">
                Det finnes flere søketreff, skriv mer for å begrense søket
              </Text>
            )}
          <VStack as="ul" alignItems="flex-start">
            {data.matrikkelenheter.map((enkelAdresse: EnkelAdresse) => (
              <MatrikkelEnhet
                key={enkelAdresse.id}
                enkelAdresse={enkelAdresse}
              />
            ))}
          </VStack>
        </>
      );
    } else {
      return (
        <>
          <Heading as="h3" size="md" mb="2">
            Søkeresultat
          </Heading>
          <Text fontSize="md" fontWeight="bold">
            Søket ditt ga ingen treff
          </Text>
        </>
      );
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  return (
    <>
      <Container
        maxWidth="max-content"
        paddingX={isAbove550px ? 5 : 2}
        paddingBottom={isAbove550px ? "15px" : "7.5px"}
      >
        <EiendomIcon size={isAbove550px ? "lg" : "md"} />
      </Container>
      <VStack maxWidth="824px" margin="auto" gap="24px">
        <GreenContainer maxWidth="max-content">
          <Flex
            width="container.md"
            maxWidth="100%"
            justify="space-between"
            alignItems="center"
            marginBottom="4"
          >
            <Heading as="h2" size={isAbove550px ? "md" : "sm"}>
              Finn informasjon om en eiendom
            </Heading>
            <InfoPopover label="Hvordan søker jeg?" popoverTrigger="hover">
              <Stack>
                <Text fontWeight="bold">Søk etter adresse</Text>
                <SokEksempel>Kartverksveien 21</SokEksempel>
                <Text>
                  For å snevre inn søket kan du legge til et stedsnavn eller
                  postnummer etter komma i søket.
                </Text>
                <SokEksempel>Kartverksveien, Hønefoss</SokEksempel>
                <SokEksempel>Kartverksveien 21, 3511</SokEksempel>
                <Text>Du kan også søke på bruksenhetsnummer.</Text>
                <SokEksempel>Kartverksveien 21 H0101</SokEksempel>
                <SokEksempel>Kartverksveien H0601, Ringerike</SokEksempel>
                <hr />
                <Text fontWeight="bold">Søk etter matrikkelnummer</Text>
                <SokEksempel>3305-38/98/0/0</SokEksempel>
                <SokEksempel>38/98, Ringerike</SokEksempel>
                <SokEksempel>Ringerike-38/98</SokEksempel>
              </Stack>
            </InfoPopover>
          </Flex>
          <Text fontSize="sm" as="label" htmlFor="inputFieldID">
            Søk med adresse eller matrikkelnummer
          </Text>
          <Input
            id="inputFieldID"
            type="search"
            value={query}
            size="lg"
            variant="outline"
            placeholder="Kartverksveien 21, Hønefoss"
            _placeholder={{ fontStyle: "italic", opacity: 0.4 }}
            border={0}
            backgroundColor="white"
            onChange={handleChange}
            autoFocus
          ></Input>
          {query.length > 2 && (
            <HStack paddingTop="15px" flexWrap="wrap">
              <Button
                size={isAbove550px ? "md" : "sm"}
                variant="secondary"
                rightIcon="open_in_new"
                onClick={() => window.open("https://norgeskart.no", "_blank")}
              >
                <Text>{isAbove550px && "Gå til "}Norgeskart</Text>
              </Button>
              {featureFlags?.showMinEiendomLink && (
                <Button
                  size={isAbove550px ? "md" : "sm"}
                  variant="secondary"
                  rightIcon="open_in_new"
                  onClick={() =>
                    window.open("https://mineiendom.kartverket.no", "_blank")
                  }
                >
                  <Text>{isAbove550px && "Logg inn på "}Min eiendom</Text>
                </Button>
              )}
            </HStack>
          )}
          {(data || isLoading || error) && (
            <Box paddingTop={5}>{renderResults()}</Box>
          )}
        </GreenContainer>
        {query.length <= MIN_SEARCH_CHAR_COUNT && (
          <HStack
            gap="24px"
            width="100%"
            flexDirection={isAbove550px ? "row" : "column"}
            justifyContent="stretch"
            alignItems="stretch"
          >
            <Card size="sm" flex="1" justifySelf="stretch">
              <CardBody>
                <Heading as="h3" size="sm" marginBottom="2">
                  Norgeskart
                </Heading>
                <Text>Vil du finne en eiendom i kartet?</Text>
                <Link href="https://norgeskart.no" target="_blank">
                  Gå til norgeskart.no
                </Link>
              </CardBody>
            </Card>
            {featureFlags?.showMinEiendomLink && (
              <Card size="sm" flex="1" justifySelf="stretch">
                <CardBody>
                  <Heading as="h3" size="sm" marginBottom="2">
                    Min eiendom
                  </Heading>
                  <Text>Vil du se opplysninger om eiendommene du eier?</Text>
                  <Link href="https://mineiendom.kartverket.no" target="_blank">
                    Logg inn på Min eiendom
                  </Link>
                </CardBody>
              </Card>
            )}
          </HStack>
        )}
      </VStack>
    </>
  );
};

const SokEksempel = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box bg="green.50" rounded="4" paddingX="2" paddingY="1">
      {children}
    </Box>
  );
};
