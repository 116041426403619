import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Text,
} from "@kvib/react";
import { useBasicInfo } from "api/useBasicInfo";
import { useParams } from "react-router-dom";

import { getIdentString } from "./getIdentString";
import { SkeletonLoader } from "./SkeletonLoader";

export const EiendomInfoHeader = () => {
  const ident = useParams();
  const { data: basicInfo, error, isLoading } = useBasicInfo(ident);

  if (isLoading) {
    return <SkeletonLoader isLoading={isLoading} />;
  }

  if (error != undefined) {
    return (
      <Alert status="error" maxWidth="400px">
        <AlertIcon />
        <AlertTitle>Kunne ikke hente informasjon om eiendom</AlertTitle>
      </Alert>
    );
  }

  return (
    <Flex justifyContent="space-between" flexWrap="wrap">
      <Box>
        <Heading as="h1" size="md" marginBottom={2}>
          {basicInfo.matrikkelIdent &&
            `Eiendom ${getIdentString(basicInfo.matrikkelIdent)}`}
        </Heading>
        {basicInfo.borettslagnavn && (
          <Text fontSize="lg">{basicInfo.borettslagnavn}</Text>
        )}
        <Text fontSize="lg">{basicInfo.adresse}</Text>
        {basicInfo.postnummeromraade && (
          <Text fontSize="lg">{basicInfo.postnummeromraade}</Text>
        )}
        <Text fontSize="lg">{basicInfo.kommune}</Text>
      </Box>
      <Box marginTop={{ base: 4, lg: 0 }}>
        <ButtonGroup gap="4">
          <Button
            leftIcon="print"
            size="xl"
            variant="tertiary"
            colorScheme="gray"
            onClick={(e) => {
              e.preventDefault();
              window.print();
            }}
          >
            Skriv ut
          </Button>
          <Button
            as="a"
            href="/"
            leftIcon="search"
            size="xl"
            variant="tertiary"
            colorScheme="gray"
          >
            Søk på nytt
          </Button>
        </ButtonGroup>
      </Box>
    </Flex>
  );
};
