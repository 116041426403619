import React from "react";

import {
  Bestilling,
  BestillingType,
  GrunnboksutskriftBestillingBorettslag,
  GrunnboksutskriftBestillingFastEiendom,
  TinglystDokumentBestilling,
} from "../../../shared/bestillingTypes";
import BestillGrunnbokBorettslagDisplayObject from "../BestillDisplayObjects/BestillGrunnbokBorettslagDisplayObject";
import BestillGrunnbokFastEiendomDisplayObject from "../BestillDisplayObjects/BestillGrunnbokFastEiendomDisplayObject";
import BestillPantebokDisplayObject from "../BestillDisplayObjects/BestillPantebokDisplayObject";

type Props = {
  bestilling: Bestilling;
};

const BestillDisplayObject = ({ bestilling }: Props) => {
  switch (bestilling.bestillingType) {
    case BestillingType.PANTEBOK:
      return (
        <BestillPantebokDisplayObject
          tinglystDokumentBestilling={bestilling as TinglystDokumentBestilling}
        />
      );
    case BestillingType.GRUNNBOK_FAST_EIENDOM:
      return (
        <BestillGrunnbokFastEiendomDisplayObject
          grunnboksutskriftBestillingFastEiendom={
            bestilling as GrunnboksutskriftBestillingFastEiendom
          }
        />
      );
    case BestillingType.GRUNNBOK_BORETTSLAG:
      return (
        <BestillGrunnbokBorettslagDisplayObject
          grunnboksutskriftBestillingBorettslag={
            bestilling as GrunnboksutskriftBestillingBorettslag
          }
        />
      );
  }
};

export default BestillDisplayObject;
