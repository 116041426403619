import React, { createContext, ReactNode, useContext, useState } from "react";

import { Bestiller } from "../../api/bestillApiDataTypes";

interface BestillerContextType {
  bestiller: Bestiller | null;
  setBestiller: (newBestiller: Bestiller) => void;
}

const BestillerContext = createContext<BestillerContextType>({
  bestiller: null,
  setBestiller: () => {},
});

const useBestiller = () => {
  const [bestiller, setBestiller] = useState<Bestiller | null>(null);

  return {
    bestiller,
    setBestiller,
  };
};

interface BestillerProviderProps {
  children: ReactNode;
}

export const BestillerProvider = ({ children }: BestillerProviderProps) => {
  const { bestiller, setBestiller } = useBestiller();

  return (
    <BestillerContext.Provider value={{ bestiller, setBestiller }}>
      {children}
    </BestillerContext.Provider>
  );
};

export const useBestillerContext = () => {
  const context = useContext(BestillerContext);
  if (context === undefined) {
    throw new Error(
      "useBestillerContext must be used within a BestillerProvider",
    );
  }

  return context;
};
