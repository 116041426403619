import * as React from "react";

type Props = {
  height?: string;
  width?: string;
};

const LogoIcon: React.FC<Props> = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 158 56"
    xmlSpace="preserve"
    width={width}
    height={height}
    aria-labelledby="kartverket_logo"
  >
    <title id="kartverket_logo">Kartverket logo</title>
    <path
      d="M35.4 2.3H5.8C4.3 2.3 3.1 3.5 3.1 5v18.2c2.3-1.8 5.2-4.2 10.5-2.9 4.5 1.1 5.5 4.6 12.7 8.6 4.4 2.4 8.5.6 11.9-1.4V5c-.1-1.5-1.3-2.7-2.8-2.7"
      style={{
        fill: "#249446",
      }}
    />
    <path
      d="M28.5 36.9c-1.5-.2-2.6-.7-3.8-1.4-2.5-1.5-4.9-4.1-10-6.7-4.1-2-8-.5-11.7 1.6v20.4c0 1.5 1.2 2.7 2.7 2.7h29.6c1.5 0 2.7-1.2 2.7-2.7V34.1c-3.1 1.8-6.1 3.3-9.5 2.8"
      style={{
        fill: "#1a589f",
      }}
    />
    <path
      d="M26.2 28.8c-7.2-4-8.1-7.4-12.7-8.6-5.2-1.3-8.2 1.2-10.5 2.9v7.2c3.6-2.1 7.7-3.7 11.7-1.6 5.1 2.7 7.5 5.2 10 6.7 1.1.7 2.3 1.2 3.8 1.4 3.4.5 6.4-1 9.5-2.8v-6.7c-3.3 2-7.4 3.9-11.8 1.5"
      style={{
        fill: "#fff",
      }}
    />
    <path d="M47.5 44.2V27.4h2v16.8h-2zm4.4-8.9 6.7 8.9H56l-6.4-8.9 6.5-7.9h2.6l-6.8 7.9zM69.7 44.6c-.8-.3-1.4-.9-1.7-1.8-1.2 1.2-2.1 1.6-3.5 1.6-3 0-3.9-1.8-3.9-3.5 0-2.8 2.4-4.4 6.5-4.4h.9v-.9c0-1.1 0-1.5-.4-1.9-.4-.4-.9-.7-1.6-.7-1.4 0-3.1.7-3.9 1.4l-1-1.4c1.6-1.1 3.4-1.6 5.1-1.6 1.7 0 2.9.7 3.4 1.9.3.7.3 1.6.3 2.7l-.1 4c0 2 .1 2.5 1 3l-1.1 1.6zm-2.8-6.7c-3.1 0-4.2 1.1-4.2 2.9 0 1.4.7 2.2 2.1 2.2 1.2 0 2.4-.8 3-1.9l.1-3.2c-.3.1-.7 0-1 0zM79.3 33.8c-.1 0-.3-.1-.5-.1-.7 0-1.5.3-2 .9-.6.6-.7.9-.7 1.9v7.6h-1.9v-9.4c0-1.6-.3-2.4-.4-2.6l1.9-.5c.1.2.5 1.1.4 2 .9-1.2 2.2-2 3.4-2 .3 0 .6.1.7.1l-.9 2.1zM88 33.4h-2.5v7.9c0 1.3.4 1.8 1.6 1.8.5 0 .9-.1 1.2-.2l.3 1.2c-.6.3-1.3.5-2.2.5-.6 0-1.1-.1-1.6-.3-.9-.4-1.2-1.2-1.2-2.4v-8.4H82V32h1.6c0-1.1.1-2.7.2-3.1l2-.4c0 .4-.2 2.1-.2 3.5h3.1l-.7 1.4zM96.9 44.3H95l-4.4-12.2 2-.4 2.8 8.4c.3.9.5 1.9.6 2.1 0-.2.2-1.1.6-2.1l2.8-8.1h2l-4.5 12.3zM105.5 38.4v.3c0 1.2.1 2 .6 2.7.7 1.1 1.8 1.5 3 1.5s2.1-.4 3-1.2l.7 1.2c-1.1 1-2.5 1.5-4.1 1.5-3.4 0-5.4-2.4-5.4-6.5 0-2.1.4-3.4 1.5-4.6 1-1.2 2.2-1.7 3.6-1.7 1.3 0 2.5.5 3.3 1.3 1.1 1.1 1.4 2.2 1.4 5.1v.3h-7.6zm5.1-4.2c-.4-.7-1.3-1.1-2.2-1.1-1.7 0-2.7 1.3-2.8 3.8h5.6c-.1-1.3-.3-2.1-.6-2.7zM121.9 33.8c-.1 0-.3-.1-.5-.1-.7 0-1.5.3-2 .9-.6.6-.7.9-.7 1.9v7.6h-1.9v-9.4c0-1.6-.3-2.4-.4-2.6l1.9-.5c.1.2.5 1.1.4 2 .9-1.2 2.2-2 3.4-2 .3 0 .6.1.7.1l-.9 2.1zM125.3 44.2v-15c0-1.4-.2-2.3-.3-2.5l1.9-.4c.1.2.3 1.2.3 2.9v15h-1.9zm7.6 0-5.6-6.9L132 32h2.4l-4.8 5.3 5.9 6.9h-2.6zM138.9 38.4v.3c0 1.2.1 2 .6 2.7.7 1.1 1.8 1.5 3 1.5s2.1-.4 3-1.2l.7 1.2c-1.1 1-2.5 1.5-4.1 1.5-3.4 0-5.4-2.4-5.4-6.5 0-2.1.4-3.4 1.5-4.6 1-1.2 2.2-1.7 3.6-1.7 1.3 0 2.5.5 3.3 1.3 1.1 1.1 1.4 2.2 1.4 5.1v.3h-7.6zm5-4.2c-.4-.7-1.3-1.1-2.2-1.1-1.7 0-2.7 1.3-2.8 3.8h5.6c-.1-1.3-.2-2.1-.6-2.7zM154.7 33.4h-2.5v7.9c0 1.3.4 1.8 1.6 1.8.5 0 .9-.1 1.2-.2l.3 1.2c-.6.3-1.3.5-2.2.5-.6 0-1.1-.1-1.6-.3-.9-.4-1.2-1.2-1.2-2.4v-8.4h-1.6V32h1.6c0-1.1.1-2.7.2-3.1l2-.4c0 .4-.2 2.1-.2 3.5h3.1l-.7 1.4z" />
  </svg>
);

export default LogoIcon;
