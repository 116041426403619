import { Hide, Show } from "@kvib/react";

import AccordionTables from "./AccordionTables";
import PanelTable from "./PanelTable";

type PanelDataProps = {
  caption: string;
  // eslint-disable-next-line no-undef
  headers: JSX.Element[];
  rows: {
    // eslint-disable-next-line no-undef
    data: JSX.Element[];
    subtitle?: string;
    title: string;
  }[];
};
const PanelData = ({ caption, headers, rows }: PanelDataProps) => {
  return (
    <>
      <Show below="xl">
        <AccordionTables headers={headers} rows={rows} />
      </Show>
      <Hide below="xl">
        <PanelTable
          caption={caption}
          headers={headers}
          rows={rows.map(({ data }) => data)}
        />
      </Hide>
    </>
  );
};

export default PanelData;
