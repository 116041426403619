import styled from "@emotion/styled";
import { Box, HStack, Text } from "@kvib/react";
import { EnkelAdresse } from "api/apiDataTypes";
import React from "react";

import {
  buildMatrikkelnummer,
  formatVeiadresse,
  getBoligIcon,
  getBoligType,
} from "./utils";

const MatrikkelEnhetLink = styled.a`
  display: block;
  padding: 20px;
  margin-top: 5px;
  border-radius: 5px;
  background-color: var(--kvib-colors-white);
  transition: all 100ms;

  &:hover {
    background-color: var(--kvib-colors-gray-50);
  }
`;

interface Props {
  enkelAdresse: EnkelAdresse;
}

export const MatrikkelEnhet: React.FC<Props> = ({ enkelAdresse }) => {
  return (
    <Box width="100%">
      <MatrikkelEnhetLink
        href={`/eiendom/${buildMatrikkelnummer(enkelAdresse)}`}
      >
        <HStack alignItems="middle">
          <span className="material-symbols-outlined">
            {getBoligIcon(enkelAdresse.boligType)}
          </span>
          <Text as="b">{getBoligType(enkelAdresse.boligType)}</Text>
        </HStack>
        {enkelAdresse.borettslagnavn && (
            <Text>{enkelAdresse.borettslagnavn}</Text>
        )}
        <Text>{buildMatrikkelnummer(enkelAdresse)}</Text>
        <Text>
          {formatVeiadresse(enkelAdresse.veiadresse, enkelAdresse.boligType)}
        </Text>
        <Text>{enkelAdresse.kommunenavn}</Text>
      </MatrikkelEnhetLink>
    </Box>
  );
};
