import { SkeletonText, SkeletonTextProps } from "@kvib/react";

type Props = {
  items: SkeletonTextProps[];
  sharedProps?: SkeletonTextProps;
};

const SkeletonTextList = ({ items, sharedProps }: Props) => {
  return (
    <>
      {items.map((itemProps, index) => (
        <SkeletonText key={index} {...sharedProps} {...itemProps} />
      ))}
    </>
  );
};

export default SkeletonTextList;
