import { Stack } from "@kvib/react";
import React from "react";
import SkeletonTextList from "shared/components/SkeletonTextLoader";

interface Props {
  isLoading: boolean;
}

export const SkeletonLoader: React.FC<Props> = ({ isLoading }) => (
  <Stack>
    <SkeletonTextList
      items={[
        { width: "100px" },
        { width: "150px" },
        { width: "250px" },
        { width: "100px" },
      ]}
      sharedProps={{
        startColor: "green.300",
        endColor: "green.200",
        noOfLines: 1,
        skeletonHeight: "20px",
        isLoaded: !isLoading,
      }}
    />
  </Stack>
);
