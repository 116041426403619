import {
  BorettslagIdent,
  DokumentIdent,
  MatrikkelIdent,
} from "../api/bestillApiDataTypes";

export type Question = {
  title: string;
  answers: {
    label: string;
    value: string;
    price?: string;
  }[];
};

export enum BestillingType {
  PANTEBOK = "PANTEBOK",
  GRUNNBOK_FAST_EIENDOM = "GRUNNBOK_FAST_EIENDOM",
  GRUNNBOK_BORETTSLAG = "GRUNNBOK_BORETTSLAG",
}

export type TinglystDokumentBestilling = {
  bestillingType: BestillingType;
  dokumentIdent: DokumentIdent;
  tinglystDato: string;
};

type GrunnboksutskriftBestilling = {
  bestillingType: BestillingType;
  historisk: boolean;
  nynorsk: boolean;
};

export type GrunnboksutskriftBestillingBorettslag =
  GrunnboksutskriftBestilling & {
    borettslagandelIdent: BorettslagIdent;
  };

export type GrunnboksutskriftBestillingFastEiendom =
  GrunnboksutskriftBestilling & {
    matrikkelIdent: MatrikkelIdent;
  };

export type Bestilling =
  | TinglystDokumentBestilling
  | GrunnboksutskriftBestillingBorettslag
  | GrunnboksutskriftBestillingFastEiendom;

export const isGrunnboksutskriftFastEiendomBestilling = (
  bestilling: Bestilling,
): bestilling is GrunnboksutskriftBestillingFastEiendom => {
  return bestilling.bestillingType === BestillingType.GRUNNBOK_FAST_EIENDOM;
};

export const isGrunnboksutskriftBorettslagBestilling = (
  bestilling: Bestilling,
): bestilling is GrunnboksutskriftBestillingBorettslag => {
  return bestilling.bestillingType === BestillingType.GRUNNBOK_BORETTSLAG;
};

export const isTinglystDokumentBestilling = (
  bestilling: Bestilling,
): bestilling is TinglystDokumentBestilling => {
  return bestilling.bestillingType === BestillingType.PANTEBOK;
};
