import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Link,
} from "@kvib/react";
import React from "react";

import { useGetDriftsmeldinger } from "../../api/useGetDriftsmeldinger";

export const Driftsmelding: React.FC = () => {
  const driftsmeldingState = useGetDriftsmeldinger();

  if (driftsmeldingState.loading || driftsmeldingState.error) {
    return null;
  }

  const driftsmeldinger = driftsmeldingState.data;

  return (
    <>
      {driftsmeldinger?.map(
        (driftsmelding, index) =>
          driftsmelding?.tittel && (
            <Alert key={index} paddingBlock="3" status="warning">
              <AlertIcon />
              <Box>
                <AlertTitle>{driftsmelding.tittel}</AlertTitle>
                <AlertDescription>
                  {driftsmelding.beskrivelse}{" "}
                  {driftsmelding.lenke && driftsmelding.lenketekst && (
                    <Link isExternal href={driftsmelding.lenke}>
                      {driftsmelding.lenketekst}
                    </Link>
                  )}
                </AlertDescription>
              </Box>
            </Alert>
          ),
      )}
    </>
  );
};
