import { useAuthenticationFlow } from "@kartverket/frontend-aut-lib";
import { Button, Link } from "@kvib/react";
import useBlobFetcher from "api/useBlobFetcher";
import React, { useEffect, useState } from "react";

import ErrorModal from "./ErrorModal";
import PDFViewer from "./PDFViewer";

interface Props {
  asLink?: boolean;
  disabled?: boolean;
  filnavn: string;
  grunnboksutskriftUrl: string;
  redirectAfterAuth: string;
  size: "sm" | "md" | "lg" | undefined;
}

export const VisGrunnbokButtonBase: React.FC<Props> = ({
  disabled,
  size = "lg",
  asLink,
  grunnboksutskriftUrl,
  redirectAfterAuth,
  filnavn,
}) => {
  const { isAuthenticatedFunc, handleAuthenticateFunc, handleLogoutFunc } =
    useAuthenticationFlow();
  const [fetchBlob, setFetchBlob] = useState("");
  const [pdfViewOpen, setPdfViewOpen] = useState(false);
  const { data, isLoading: isLoadingBlob, error } = useBlobFetcher(fetchBlob);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);

  useEffect(() => {
    if (error) {
      setErrorModalIsOpen(true);
    }
  }, [error]);

  const errorMessage = error
    ? error instanceof Error
      ? error.message === "Too many requests"
        ? {
            heading: "For mange visninger av grunnboken",
            body: "Du har oversteget antall tillatte visninger av grunnboken per innlogging, og må logge inn på nytt hvis du vil se flere.\n\nDu vil nå bli tatt til startsiden av Se eiendom.",
            onClose: handleLogoutFunc,
          }
        : {
            heading: "Det har oppstått en feil",
            body: error.message,
            onClose: () => setErrorModalIsOpen(false),
          }
      : {
          heading: "Det har oppstått en feil",
          body: String(error),
          onClose: () => setErrorModalIsOpen(false),
        }
    : undefined;

  const onClick = () => {
    if (!isAuthenticatedFunc()) {
      handleAuthenticateFunc(redirectAfterAuth);
    } else if (error) {
      setErrorModalIsOpen(true);
    } else {
      setFetchBlob(grunnboksutskriftUrl);
      setPdfViewOpen(true);
    }
  };

  return (
    <>
      {asLink ? (
        <Link as="button" onClick={onClick} marginBottom={{ base: 3, lg: 0 }}>
          Vis grunnboken
        </Link>
      ) : (
        <Button
          colorScheme="green"
          size={size}
          isDisabled={disabled}
          isLoading={isLoadingBlob}
          onClick={onClick}
        >
          Vis grunnboken
        </Button>
      )}
      {pdfViewOpen && data && (
        <PDFViewer
          blob={data}
          filnavn={filnavn}
          onClose={() => setPdfViewOpen(false)}
        />
      )}
      {error && errorMessage && (
        <ErrorModal errorMessage={errorMessage} isOpen={errorModalIsOpen} />
      )}
    </>
  );
};
