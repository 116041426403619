import { BoligType, EnkelAdresse } from "api/apiDataTypes";

export const getBoligType = (type: BoligType): string => {
  switch (type) {
    case BoligType.Eiendom:
    case BoligType.IkkeSeksjonertEiendom:
      return "Eiendom";
    case BoligType.Seksjon:
      return "Seksjon";
    case BoligType.Sameie:
      return "Sameie";
    case BoligType.Borettslag:
      return "Borettslag";
    case BoligType.Borettslagandel:
      return "Borettslagandel";
    default:
      return "Annet";
  }
};

export const getBoligIcon = (boligType: BoligType): string => {
  switch (boligType) {
    case BoligType.Sameie:
      return "domain";
    case BoligType.Seksjon:
      return "real_estate_agent";
    case BoligType.Borettslag:
      return "domain";
    case BoligType.Borettslagandel:
      return "real_estate_agent";
    default:
      return "home";
  }
};

export const formatVeiadresse = (
  veiadresse: string,
  boligType: BoligType
): string => {
  if (veiadresse === "") {
    return "Ingen veiadresse";
  }

  const capitalizeFirstLetter = (word: string) =>
    word.charAt(0).toUpperCase() + word.slice(1);

  const formattedVeiadresse =
    capitalizeFirstLetter(veiadresse.split(",")[0].toLowerCase()) +
    `, ${veiadresse.split(",")[1]} ${veiadresse.split(",")[2] ?? ""}`;

  switch (boligType) {
    case BoligType.Sameie:
      return formattedVeiadresse;
    case BoligType.Seksjon:
      return veiadresse;
    case BoligType.Borettslagandel:
      return veiadresse;
    case BoligType.IkkeSeksjonertEiendom:
      return veiadresse;
    default:
      return formattedVeiadresse;
  }
};

export const buildMatrikkelnummer = (enkelAdresse: EnkelAdresse): string => {
  if (enkelAdresse.seksjonsnr == 0 && enkelAdresse.festenr == 0) {
    return `${enkelAdresse.kommunenr}/${enkelAdresse.gaardsnr}/${enkelAdresse.bruksnr}`;
  }

  if (enkelAdresse.seksjonsnr == 0) {
    return `${enkelAdresse.kommunenr}/${enkelAdresse.gaardsnr}/${enkelAdresse.bruksnr}/${enkelAdresse.festenr}`;
  }

  return `${enkelAdresse.kommunenr}/${enkelAdresse.gaardsnr}/${enkelAdresse.bruksnr}/${enkelAdresse.festenr}/${enkelAdresse.seksjonsnr}`;
};
