import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Card,
  Heading,
} from "@kvib/react";
import React from "react";

interface Props {
  children: React.ReactElement;
  subtitle?: string;
  title: string;
}

export const AccordionCard: React.FC<Props> = ({
  title,
  subtitle,
  children,
}) => {
  return (
    <AccordionItem border="none">
      <Card my={2} margin={2}>
        <AccordionButton fontSize="md" px={0}>
          <Box flex="1" textAlign="left" my={2} mx={2}>
            <Heading variant="h3" size="sm" fontWeight="normal">
              <b>{title}</b>
              {subtitle && ` – ${subtitle}`}
            </Heading>
          </Box>
          <AccordionIcon mx={2} />
        </AccordionButton>
        <AccordionPanel px={2}>{children}</AccordionPanel>
      </Card>
    </AccordionItem>
  );
};
