import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
} from "@kvib/react";
import React from "react";

interface Props {
    children?: React.ReactElement;
    status?: "error" | "info" | "warning" | "success" | "loading" | undefined;
    title: string;
}

export const Feilmelding = ({title,  children, status}: Props) => {
    return (
        <>
                <Alert paddingBlock="3" status={status ?? "error"} borderRadius="md">
                    <AlertIcon />
                    <Box>
                        <AlertTitle>{title}</AlertTitle>
                        <AlertDescription>
                            {children}
                        </AlertDescription>
                    </Box>
                </Alert>
        </>
    );
};
