import { forwardRef } from "@chakra-ui/react";
import {
  Box,
  Button,
  Hide,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Show,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@kvib/react";
import React from "react";
import { useRef } from "react";

interface Props {
  children: React.ReactNode;
  color?: "blue" | "gray";
  label?: string;
  popoverTrigger?: "click" | "hover";
}

type TriggerProps = {
  color?: "blue" | "gray";
  label?: string;
  onClick?: () => void;
};

const Trigger = forwardRef(({ onClick, label, color }: TriggerProps, ref) => {
  const showLabel =
    useBreakpointValue(
      {
        base: false,
        md: true,
      },
      { fallback: "md" }
    ) && label;

  return (
    <Button
      aria-label={label ?? "Info"}
      colorScheme={color}
      size={showLabel ? "md" : "xl"}
      ref={ref}
      variant="ghost"
      onClick={onClick}
    >
      {showLabel && <Text marginEnd="1">{label}</Text>}
      <Icon icon="info" />
    </Button>
  );
});

export const InfoPopover = ({
  children,
  label,
  color,
  popoverTrigger,
}: Props) => (
  <>
    <Show above="lg">
      <PopoverVariant
        label={label}
        color={color}
        popoverTrigger={popoverTrigger}
      >
        {children}
      </PopoverVariant>
    </Show>
    <Hide above="lg">
      <ModalVariant label={label} color={color}>
        {children}
      </ModalVariant>
    </Hide>
  </>
);

const ModalVariant = ({ children, label, color }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const focusRef = useRef(null);
  return (
    <>
      <Trigger onClick={onOpen} label={label} color={color} />
      <Modal
        isOpen={isOpen}
        isCentered
        initialFocusRef={focusRef}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent marginInline="4" marginTop="6">
          {/* Dette buttonelementet under synes ikke og eksisterer kun 
            for å holde på focus, ettersom noe inni popoveren må ha 
            focus for at den skal lukkes når focus flyttes vekk (man trykker utenfor) */}
          <button ref={focusRef} aria-hidden={true} />

          <ModalHeader />
          <ModalBody>{children}</ModalBody>
          <ModalFooter>
            <Button colorScheme="green" onClick={onClose}>
              Lukk
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const PopoverVariant = ({
  children,
  label,
  color,
  popoverTrigger = "click",
}: Props) => {
  const focusRef = useRef(null);
  return (
    <Popover trigger={popoverTrigger} initialFocusRef={focusRef}>
      <PopoverTrigger>
        <Box>
          <Trigger onClick={undefined} color={color} label={label} />
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        {/* Dette buttonelementet synes ikke og eksisterer kun 
        for å holde på focus, ettersom noe inni popoveren må ha 
        focus for at den skal lukkes når focus flyttes vekk (man trykker utenfor) */}
        <button aria-hidden={true} ref={focusRef} />

        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody
          fontFamily="body"
          fontWeight="normal"
          letterSpacing="normal"
          lineHeight="normal"
          fontSize="md"
          textTransform="none"
          color="chakra-body-text._dark"
          marginRight={5}
        >
          {children}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
