import { getBaseUrl } from "api/config";
import React from "react";
import { createSearchParams, useLocation } from "react-router-dom";
import { VisGrunnbokButtonBase } from "shared/components/VisGrunnbokButtonBase";

interface Props {
  asLink?: boolean;
  bruksnr: string;
  disabled?: boolean;
  festenr?: string;
  gaardsnr: string;
  kommunenr: string;
  seksjonsnr?: string;
  size?: "sm" | "md" | "lg" | undefined;
}

export const VisGrunnbokButton: React.FC<Props> = ({
  kommunenr,
  gaardsnr,
  bruksnr,
  festenr,
  seksjonsnr,
  disabled,
  size = "lg",
  asLink,
}) => {
  const isSeksjon = festenr !== undefined && seksjonsnr !== undefined;
  const isFestegrunn = festenr !== undefined && seksjonsnr === undefined;
  const isSeksjonEllerFestegrunn = isSeksjon || isFestegrunn;
  const location = useLocation();

  const grunnboksutskriftUrl = () => {
    const baseApiUrl = `${getBaseUrl()}/api/grunnbok/ubekreftetgrunnboksutskrift`;
    const baseParams = `${kommunenr}/${gaardsnr}/${bruksnr}`;
    const extraParams = `?festenr=${festenr ?? 0}&seksjonsnr=${
      seksjonsnr ?? 0
    }`;

    return `${baseApiUrl}/${baseParams}${extraParams}`;
  };

  const redirectAfterAuthSearchParams = createSearchParams({
    kommunenr,
    gaardsnr,
    bruksnr,
    ...(isSeksjonEllerFestegrunn
      ? {
          grunnboksutskrift: (isSeksjon ? "seksjon" : "festegrunn"),
          festenr,
          seksjonsnr: seksjonsnr ?? "0",
        }
      : { grunnboksutskrift: "eiendom" }),
  });

  const filnavnVedNedlasting = `grunnboksutskrift_${kommunenr}_${gaardsnr}_${bruksnr}${
    isSeksjonEllerFestegrunn ? `_${festenr}_${seksjonsnr ?? 0}` : ""
  }`;

  return (
    <VisGrunnbokButtonBase
      grunnboksutskriftUrl={grunnboksutskriftUrl()}
      disabled={disabled}
      size={size}
      filnavn={filnavnVedNedlasting}
      asLink={asLink}
      redirectAfterAuth={`${location.pathname}?${redirectAfterAuthSearchParams}`}
    />
  );
};
