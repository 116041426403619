import { useAuthenticationFlow } from "@kartverket/frontend-aut-lib";
import { Box, Flex, Heading, Link, SkeletonText, Text } from "@kvib/react";
import { MatrikkelenhetIdent } from "api/apiDataTypes";
import { getBaseUrl } from "api/config";
import useBlobFetcher from "api/useBlobFetcher";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { BestillUtskriftButton } from "shared/components/BestillUtskriftButton";
import ErrorModal from "shared/components/ErrorModal";
import { InfoPopover } from "shared/components/InfoButton";
import PDFViewer from "shared/components/PDFViewer";
import { VisGrunnbokButton } from "shared/components/VisGrunnbokButton";

interface Props {
  borettslag: boolean;
  ident: MatrikkelenhetIdent;
  isLoading: boolean;
  tinglyst: boolean;
  utgatt: boolean;
}

type PDFErrorMessage = {
  body: string;
  heading: string;
  onClose: () => void;
};

export const GrunnboksInfo = ({
  borettslag,
  tinglyst,
  isLoading,
  utgatt,
  ident,
}: Props) => {
  const [searchParams] = useSearchParams();
  const [blobUrl, setBlobUrl] = useState("");
  const [filnavn, setFilnavn] = useState("");
  const [pdfErrorModalIsOpen, setPdfErrorModalIsOpen] = useState(false);
  const [pdfErrorMessage, setPdfErrorMessage] = useState<PDFErrorMessage>();

  const { handleLogoutFunc } = useAuthenticationFlow();
  const {
    data: pdfBlob,
    isLoading: isLoadingPdf,
    error: pdfError,
  } = useBlobFetcher(blobUrl);
  const [pdfViewOpen, setPdfViewOpen] = useState(
    !isLoadingPdf && pdfBlob != undefined,
  );

  useEffect(() => {
    setPdfViewOpen(pdfBlob != undefined);
  }, [pdfBlob]);

  useEffect(() => {
    setPdfErrorMessage(
      pdfError
        ? pdfError instanceof Error
          ? pdfError.message === "Too many requests"
            ? {
                heading: "For mange visninger av grunnboken",
                body: "Du har oversteget antall tillatte visninger av grunnboken per innlogging, og må logge inn på nytt hvis du vil se flere.\n\nDu vil nå bli tatt til startsiden av Se eiendom.",
                onClose: handleLogoutFunc,
              }
            : {
                heading: "Det har oppstått en feil",
                body: pdfError.message,
                onClose: () => setPdfErrorModalIsOpen(false),
              }
          : {
              heading: "Det har oppstått en feil",
              body: String(pdfError),
              onClose: () => setPdfErrorModalIsOpen(false),
            }
        : undefined,
    );

    setPdfErrorModalIsOpen(pdfError);
  }, [handleLogoutFunc, pdfError]);

  useEffect(() => {
    const getGrunnboksutksrift = () => {
      const baseApiUrl = `${getBaseUrl()}/api/grunnbok/ubekreftetgrunnboksutskrift`;
      const isAndel = searchParams.get("grunnboksutskrift") === "andel";
      const isSeksjon =
        ident.festenr !== undefined && ident.seksjonsnr !== undefined;
      const isFestegrunn = ident.festenr !== undefined && ident.seksjonsnr === undefined;
      const isSeksjonEllerFestegrunn = isSeksjon || isFestegrunn;

      const extraParams = isSeksjonEllerFestegrunn
        ? `?festenr=${ident.festenr}&seksjonsnr=${ident.seksjonsnr ?? 0}`
        : "";
      const borettslagsnummer = searchParams.get("borettslagnummer");
      const andelsnummer = searchParams.get("andelsnummer");

      setBlobUrl(
        isAndel
          ? `${baseApiUrl}/${borettslagsnummer}/${andelsnummer}${extraParams}`
          : `${baseApiUrl}/${ident.kommunenr}/${ident.gaardsnr}/${ident.bruksnr}${extraParams}`,
      );

      setFilnavn(
        isAndel
          ? `grunnboksutskrift_${borettslagsnummer}_${andelsnummer}`
          : `grunnboksutskrift_${ident.kommunenr}_${ident.gaardsnr}_${
              ident.bruksnr
            }${isSeksjonEllerFestegrunn ? `_${ident.festenr}_${ident.seksjonsnr ?? 0}` : ""}`,
      );
    };

    const utskriftstype = searchParams.get("grunnboksutskrift");
    if (
      utskriftstype !== null &&
      ["eiendom", "seksjon", "andel", "festegrunn"].includes(utskriftstype)
    ) {
      getGrunnboksutksrift();
    }
  }, [
    ident.bruksnr,
    ident.festenr,
    ident.gaardsnr,
    ident.kommunenr,
    ident.seksjonsnr,
    searchParams,
  ]);

  return (
    <Box width={{ base: "100%", sm: "auto" }}>
      {pdfErrorModalIsOpen && pdfErrorMessage && (
        <ErrorModal
          isOpen={pdfErrorModalIsOpen}
          errorMessage={pdfErrorMessage}
        />
      )}
      {pdfViewOpen && pdfBlob && (
        <PDFViewer
          blob={pdfBlob}
          filnavn={filnavn}
          onClose={() => setPdfViewOpen(false)}
        />
      )}

      <Heading as="h2" size="lg">
        Informasjon fra grunnboken
      </Heading>
      {isLoading ? (
        <SkeletonText paddingY="7" noOfLines={4} skeletonHeight="20px" />
      ) : (
        <Flex direction="column" gap="10" marginTop="3">
          {tinglyst && (
            <Flex gap="2">
              <Text lineHeight="7" as="p">
                Se hva som er tinglyst på eiendommen
              </Text>
              <InfoPopover color="blue">
                <Text>
                  Vis hva som er tinglyst (krever innlogging), eventuelt bestill
                  bekreftet grunnboksutskrift eller kopi av tinglyst dokument.{" "}
                  <Link
                    href="https://kartverket.no/eiendom/bestille-fra-grunnboken/sjekk-hva-som-er-tinglyst"
                    isExternal
                  >
                    Les mer om grunnboken.
                  </Link>
                </Text>
              </InfoPopover>
            </Flex>
          )}
          {borettslag && (
            <Text>
              For å se hva som er tinglyst på en borettslagsandel, se under
              Borettslagsandeler.
            </Text>
          )}
          {(tinglyst && !utgatt) || isLoading ? (
            <Flex
              flexDir={{ base: "column", sm: "row" }}
              gap="5"
              justifyContent="stretch"
            >
              {ident.kommunenr && ident.gaardsnr && ident.bruksnr && (
                <VisGrunnbokButton
                  kommunenr={ident.kommunenr}
                  gaardsnr={ident.gaardsnr}
                  bruksnr={ident.bruksnr}
                  festenr={ident.festenr}
                  seksjonsnr={ident.seksjonsnr}
                />
              )}
              <BestillUtskriftButton />
            </Flex>
          ) : (
            <Text marginTop={8}>
              {utgatt
                ? "Eiendommen er registrert som historisk i grunnboken."
                : "Eiendommen er ikke tinglyst."}
              <br />
              Grunnboksutskrift for eiendommen er derfor ikke tilgjengelig.
            </Text>
          )}
        </Flex>
      )}
    </Box>
  );
};
