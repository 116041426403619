import { Flex, TableCellProps, Td, Th } from "@kvib/react";
import React from "react";

export const AccordionTableCell: React.FC<TableCellProps> = ({
  children,
}: TableCellProps) => {
  return (
    <Td
      paddingInline="1"
      width={{ base: "50%", md: "auto" }}
      wordBreak="break-word"
      whiteSpace="initial"
    >
      <Flex gap={2} alignItems="center">
        {children}
      </Flex>
    </Td>
  );
};

export const TableCell = ({ children }: TableCellProps) => {
  return (
    <Td paddingInline="4" whiteSpace="normal">
      {children}
    </Td>
  );
};

export const TableHeaderCell = ({ children }: TableCellProps) => {
  return (
    <Th px="4" whiteSpace="normal">
      {children}
    </Th>
  );
};
