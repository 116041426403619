import { getBaseUrl } from "api/config";
import React from "react";
import { createSearchParams } from "react-router-dom";
import { VisGrunnbokButtonBase } from "shared/components/VisGrunnbokButtonBase";

interface Props {
  andelsnummer: number;
  asLink?: boolean;
  borettslagnummer: string;
  disabled?: boolean;
  size?: "sm" | "md" | "lg" | undefined;
}

export const VisGrunnbokButtonBorettslag: React.FC<Props> = ({
  borettslagnummer,
  andelsnummer,
  disabled,
  size = "lg",
  asLink,
}) => {
  const grunnboksutskriftUrl = () => {
    const baseApiUrl = `${getBaseUrl()}/api/grunnbok/ubekreftetgrunnboksutskrift`;
    const baseParams = `${borettslagnummer}/${andelsnummer}`;
    return `${baseApiUrl}/${baseParams}`;
  };

  const redirectAfterAuthSearchParams = createSearchParams({
    grunnboksutskrift: "andel",
    borettslagnummer,
    andelsnummer: andelsnummer.toString(),
  });

  const filnavnVedNedlasting = `grunnboksutskrift_${borettslagnummer}_${andelsnummer}`;

  return (
    <VisGrunnbokButtonBase
      grunnboksutskriftUrl={grunnboksutskriftUrl()}
      disabled={disabled}
      size={size}
      asLink={asLink}
      redirectAfterAuth={`${location.pathname}?${redirectAfterAuthSearchParams}`}
      filnavn={filnavnVedNedlasting}
    />
  );
};
