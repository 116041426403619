import styled from "@emotion/styled";
import { Grid, GridItem, Heading, Link, Stack, Text } from "@kvib/react";
import React from "react";
import { ContentContainer } from "shared/components/ContentContainer";
import LogoIcon from "shared/icons/LogoIcon";

const FooterStyled = styled.footer`
  margin-top: 80px;
  background-color: var(--kvib-colors-gray-50);
  border-top: 8px solid var(--kvib-colors-green-400);
  padding-top: var(--kvib-space-12);
  padding-bottom: var(--kvib-space-12);
`;

export const Footer: React.FC = () => {
  return (
    <FooterStyled>
      <ContentContainer>
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(4, 1fr)" }}
          gap={6}
        >
          <GridItem>
            <Link href="/" display="block" aria-label="Tilbake til forside">
              <LogoIcon height="50px" />
            </Link>
          </GridItem>
          <GridItem>
            <Stack spacing={2}>
              <Heading as="h2" fontSize="lg" marginBottom={4}>
                Kontakt oss
              </Heading>
              <Text>
                Telefon: <Link href="tel:32118000">32 11 80 00</Link>
              </Text>
              <Text>
                Epost:{" "}
                <Link href="mailto:post@kartverket.no">post@kartverket.no</Link>
              </Text>
              <Link href="https://www.kartverket.no/om-kartverket/kontakt-oss">
                Kontaktinfo og adresser
              </Link>
            </Stack>
          </GridItem>
          <GridItem>
            <Stack spacing={2}>
              <Heading as="h2" fontSize="lg" marginBottom={4}>
                Informasjon
              </Heading>
              <Link href="https://www.kartverket.no/Om-Kartverket/Personvern/">
                Personvern og cookies
              </Link>
              <Link href="https://uustatus.no/nb/erklaringer/publisert/1ec6fe4a-780f-4e10-9585-f510b022be39">
                Tilgjengelighetserklæring
              </Link>
            </Stack>
          </GridItem>
        </Grid>
      </ContentContainer>
    </FooterStyled>
  );
};
