import { Button, Tooltip } from "@kvib/react";
import React from "react";
import {Link} from "react-router-dom";

import { useFeatureFlagsContext } from "../context/FeatureFlagContext";

interface Props {
  asLink?: boolean;
  size?: "sm" | "md" | "lg" | undefined;
}

export const BestillUtskriftButton: React.FC<Props> = ({
  asLink,
  size = "lg",
}) => {
  const { enableBestilling } = useFeatureFlagsContext();

  const url = enableBestilling
    ? "/bestill"
    : "https://www.kartverket.no/eiendom/bestille-fra-grunnboken/";

  const linkContent = asLink ? (
       <> Bestill dokumenter </>
   ) : (
       <Button variant="secondary" size={size}>
           Bestill dokumenter
       </Button>
   )

    return (
        <Tooltip label="Klikk for å bestille bekreftet grunnboksutskrift eller kopi av tinglyst dokument.">
            <Link to={url} target="_blank">
                {linkContent}
            </Link>
        </Tooltip>
    )};
