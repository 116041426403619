import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Divider,
  HStack,
  Input,
  Text,
  VStack,
} from "@kvib/react";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  BestillingType,
  GrunnboksutskriftBestillingFastEiendom,
} from "../../../../shared/bestillingTypes";
import {PRIS_PR_PRODUKT} from "../../../../shared/constants";
import { useBestillingerContext } from "../../../../shared/context/BestillingerContext";
import { BASE_10_RADIX } from "../../../../shared/utils/formatUtils";

const BestillingskjemaGrunnbokFastEiendom = () => {
  const { isBestillingInList, addBestilling } = useBestillingerContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const [eiendomIdent, setEiendomIdent] = useState(
    searchParams.get("eiendomIdent") ?? "",
  );

  const isNynorsk = searchParams.get("nynorsk") === "true";
  const isHistorisk = searchParams.get("historisk") === "true";

  const permittedSeparatorsRegex = /[.,/ -]/;

  const isValid = (() => {
    const regexPattern = new RegExp(
      `^\\d{3,4}` +
        `${permittedSeparatorsRegex.source}\\d{1,3}` +
        `${permittedSeparatorsRegex.source}\\d{1,3}` +
        `(?:${permittedSeparatorsRegex.source}\\d{1,3})?` +
        `(?:${permittedSeparatorsRegex.source}\\d{1,3})?$`,
    );

    const isValidFormat = regexPattern.test(eiendomIdent);

    if (!isValidFormat) {
      return false;
    }

    const parts = eiendomIdent.split(permittedSeparatorsRegex);
    if (parts.length < 3) {
      return false;
    }

    const [
      kommunenummer,
      gaardsnummer,
      bruksnummer,
      festenummer,
      seksjonsnummer,
    ] = parts;

    if (
      isNaN(parseInt(kommunenummer, BASE_10_RADIX)) ||
      isNaN(parseInt(gaardsnummer, BASE_10_RADIX)) ||
      isNaN(parseInt(bruksnummer, BASE_10_RADIX))
    ) {
      return false;
    }

    if (festenummer && isNaN(parseInt(festenummer, BASE_10_RADIX))) {
      return false;
    }

    if (seksjonsnummer && isNaN(parseInt(seksjonsnummer, BASE_10_RADIX))) {
      return false;
    }

    return true;
  })();

  const currentBestilling: GrunnboksutskriftBestillingFastEiendom | undefined =
    (() => {
      if (!isValid) {
        return undefined;
      }

      const parts = eiendomIdent.split(permittedSeparatorsRegex);
      const [
        kommunenummer,
        gaardsnummer,
        bruksnummer,
        festenummer,
        seksjonsnummer,
      ] = parts;

      return {
        bestillingType: BestillingType.GRUNNBOK_FAST_EIENDOM,
        historisk: isHistorisk,
        nynorsk: isNynorsk,
        matrikkelIdent: {
          kommunenummer,
          gaardsnummer: parseInt(gaardsnummer, BASE_10_RADIX),
          bruksnummer: parseInt(bruksnummer, BASE_10_RADIX),
          festenummer: festenummer ? parseInt(festenummer, BASE_10_RADIX) : 0,
          seksjonsnummer: seksjonsnummer
            ? parseInt(seksjonsnummer, BASE_10_RADIX)
            : 0,
        },
      } as GrunnboksutskriftBestillingFastEiendom;
    })();

  const isAlreadyInList =
    currentBestilling && isBestillingInList(currentBestilling);

  const alertBox = (status: "error" | "warning" | "info", message: string) => {
    return (
      <Alert status={status} width="100%">
        <AlertIcon />
        <AlertTitle>{message}</AlertTitle>
      </Alert>
    );
  };

  const handleAddBestilling = () => {
    if (currentBestilling && !isAlreadyInList) {
      addBestilling(currentBestilling);
    }

    setSearchParams({});
  };

  return (
    <>
      <VStack alignItems="flex-start">
        <Text fontSize="xl" fontWeight="bold">
          Eiendomsidentifikasjon
        </Text>
        <Input
          name="eiendomIdent"
          value={eiendomIdent}
          onChange={(e) => setEiendomIdent(e.target.value)}
          placeholder='Eksempel "3305-200/32/0/1"'
          isInvalid={!isValid && eiendomIdent !== ""}
          width={300}
          size="lg"
        />
      </VStack>
      {eiendomIdent && (
        <>
          <Divider />
          <VStack alignItems="flex-start" gap={10}>
            {!isValid &&
              eiendomIdent !== "" &&
              alertBox("error", "Eiendomsidenten er ugyldig.")}
            {isValid && (
              <>
                <HStack width="100%" gap={100}>
                  <VStack alignItems="flex-start">
                    <Text fontWeight="bold">
                      Grunnboksutskrift fast eiendom
                    </Text>
                    <Text>{eiendomIdent}</Text>
                  </VStack>
                  <Text>{PRIS_PR_PRODUKT},-</Text>
                  <Button
                    onClick={handleAddBestilling}
                    isDisabled={isAlreadyInList}
                    leftIcon="add"
                    variant="secondary"
                  >
                    Legg til
                  </Button>
                </HStack>
                {isAlreadyInList &&
                  alertBox(
                    "info",
                    "Utskriften er allerede lagt til i bestillingen.",
                  )}
                {!isAlreadyInList &&
                  alertBox("warning", "Vi sjekker ikke om utskriften finnes.")}
              </>
            )}
          </VStack>
        </>
      )}
    </>
  );
};

export default BestillingskjemaGrunnbokFastEiendom;
