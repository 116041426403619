import { MatrikkelenhetIdent } from "api/apiDataTypes";

export const getIdentString = (ident: MatrikkelenhetIdent): string => {
  const { kommunenr, gaardsnr, bruksnr, festenr, seksjonsnr } = ident;
  const baseIdentString = `${kommunenr}-${gaardsnr}/${bruksnr}`;
  const festenrString = festenr || seksjonsnr ? `/${festenr || 0}` : "";
  const seksjonsnrString = seksjonsnr ? `/${seksjonsnr}` : "";

  return `${baseIdentString}${festenrString}${seksjonsnrString}`;
};
