import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  HStack,
  Link,
  Text,
  useMediaQuery,
} from "@kvib/react";
import { Seksjon } from "api/apiDataTypes";
import { useSeksjoner } from "api/useSeksjoner";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  AccordionPanelWrapper,
  AccordionPanelWrapperError,
  AccordionPanelWrapperSkeltonLoader,
} from "shared/components/AccordionPanelWrapper";
import { BestillUtskriftButton } from "shared/components/BestillUtskriftButton";
import { InfoPopover } from "shared/components/InfoButton";
import PanelData from "shared/components/PanelData";
import { VisGrunnbokButton } from "shared/components/VisGrunnbokButton";
import tokens from "shared/styles/tokens";

export const SeksjonerPanel: React.FC = () => {
  const ident = useParams();
  const { data: seksjoner, isLoading, error } = useSeksjoner(ident);
  const title = `Seksjoner (${seksjoner?.length ?? 0})`;
  const [isSmall] = useMediaQuery(`(max-width: ${tokens.breakpoints.sm})`);

  const headers = [
    <HStack key="1">
      <b>Seksjon</b>
      <InfoPopover color="blue">
        <Text>
          Klikk på matrikkelnummeret for å se eiendomsdata for seksjonen.
        </Text>
      </InfoPopover>
    </HStack>,
    <HStack key="2">
      <b>Type</b>
    </HStack>,
    <HStack key="3">
      <b>Grunnbok</b>
      <InfoPopover color="blue">
        <Text>
          Vis hva som er tinglyst (krever innlogging), eventuelt bestill
          bekreftet grunnboksutskrift eller kopi av tinglyst dokument.{" "}
          <Link
            href="https://kartverket.no/eiendom/bestille-fra-grunnboken/sjekk-hva-som-er-tinglyst"
            isExternal
          >
            Les mer om grunnboken
          </Link>
        </Text>
      </InfoPopover>
    </HStack>,
  ];

  const rowElements = (seksjon: Seksjon, isSmall: boolean) => [
    <Box key="1">
      <Link
        href={`/eiendom/${seksjon.ident.kommunenr}/${seksjon.ident.gaardsnr}/${seksjon.ident.bruksnr}/${seksjon.ident.festenr}/${seksjon.ident.seksjonsnr}`}
      >
        {seksjon.ident.kommunenr}-{seksjon.ident.gaardsnr}/
        {seksjon.ident.bruksnr}-{seksjon.ident.festenr}/
        {seksjon.ident.seksjonsnr}
      </Link>
    </Box>,
    <Box key="2">{seksjon.type}</Box>,
    <Flex key="3" gap="5" flexWrap="wrap">
      {seksjon.isTinglyst ? (
        <>
          <VisGrunnbokButton
            kommunenr={seksjon.ident.kommunenr}
            gaardsnr={seksjon.ident.gaardsnr}
            bruksnr={seksjon.ident.bruksnr}
            festenr={seksjon.ident.festenr}
            seksjonsnr={seksjon.ident.seksjonsnr}
            size="md"
            asLink={isSmall}
          />
          <BestillUtskriftButton size="md" asLink={isSmall} />
        </>
      ) : (
        <Alert status="info" width="auto">
          <AlertIcon />
          Seksjonen er ikke tinglyst
        </Alert>
      )}
    </Flex>,
  ];

  const rows = useMemo(() => {
    return (
      seksjoner?.map((seksjon) => ({
        title: `${seksjon.type} ${seksjon.ident.seksjonsnr}`,
        data: rowElements(seksjon, isSmall),
      })) ?? []
    );
  }, [seksjoner, isSmall]);

  if (isLoading) {
    return <AccordionPanelWrapperSkeltonLoader title="Seksjoner" />;
  }

  if (error) {
    return (
      <AccordionPanelWrapperError
        title="Seksjoner"
        errorText="Kunne ikke hente seksjoner"
      />
    );
  }

  return (
    <AccordionPanelWrapper title={title}>
      <PanelData caption="Seksjoner" headers={headers} rows={rows} />
    </AccordionPanelWrapper>
  );
};
