import "react-pdf/dist/Page/TextLayer.css";

import {
  Button,
  Center,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Show,
  SkeletonText,
  Text,
} from "@kvib/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = url;

type PDFModalProps = {
  blob: Blob;
  filnavn: string;
  onClose: () => void;
};

const PDFViewer = ({ filnavn, blob, onClose }: PDFModalProps) => {
  const [numPages, setNumPages] = useState<number>();

  const pdfContainerRef = useRef<HTMLDivElement>(null);
  const maxPdfWidth = 768;
  const [pdfWidth, setPdfWidth] = useState(maxPdfWidth);

  const tittel = filnavn.split("_").slice(1).join("/").replace("/", "-");

  const handleResize = useCallback(() => {
    setPdfWidth(
      Math.min(
        pdfContainerRef.current?.offsetWidth ?? maxPdfWidth,
        maxPdfWidth,
      ),
    );
  }, [pdfContainerRef, maxPdfWidth]);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
    handleResize();
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  return (
    <Modal allowPinchZoom isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        maxWidth="container.md"
        marginInline="2"
        backgroundColor="gray.50"
      >
        <ModalHeader
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text
            color="gray.700"
            fontSize={{ base: "md", md: "lg" }}
            fontWeight="bold"
          >{`Grunnboksutskrift for ${tittel}`}</Text>
          <HStack>
            <Button
              size="xs"
              justifySelf="right"
              as="a"
              href={window.URL.createObjectURL(blob)}
              download={filnavn}
              leftIcon="download"
            >
              <Show above="sm">Last ned</Show>
            </Button>
            <ModalCloseButton justifySelf="right" position="static" />
          </HStack>
        </ModalHeader>
        <ModalBody paddingBottom="6">
          <Document
            loading={
              <>
                <SkeletonText />
                <SkeletonText paddingBlock="5" />
                <SkeletonText paddingBottom="5" />
              </>
            }
            file={blob}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Center flexDir="column" gap="3" ref={pdfContainerRef}>
              {new Array(numPages).fill(0).map((_, i) => (
                <Page
                  key={i + 1}
                  width={pdfWidth}
                  renderAnnotationLayer={false}
                  pageNumber={i + 1}
                />
              ))}
            </Center>
          </Document>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PDFViewer;
