import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Image,
  LinkBox,
  LinkOverlay,
  Skeleton,
} from "@kvib/react";
import { useEiendomInfo } from "api/useEiendomInfo";
import { useKartutsnitt } from "api/useKartutsnitt";
import { useKartutsnittInfo } from "api/useKartutsnittInfo";
import { useTeiger } from "api/useTeiger";
import { useParams } from "react-router-dom";

export const getKartutsnittLenke = (koordinater: number[]): string =>
  `#!?project=seeiendom&zoom=16&lat=${koordinater[1]}&lon=${koordinater[0]}&markerLat=${koordinater[1]}&markerLon=${koordinater[0]}&panel=Seeiendom&showSelection=true`;

export const Kartutsnitt = () => {
  const ident = useParams();
  const {
    data: teiger,
    error: teigerError,
    isLoading: isLoadingTeiger,
  } = useTeiger(ident);

  const {
    data: kartutsnitt,
    error: kartutsnittError,
    isLoading: isLoadingKartutsnitt,
  } = useKartutsnitt(ident);

  const {
    data: kartutsnittInfo,
    error: kartutsnittInfoError,
    isLoading: isLoadingKartutsnittInfo,
  } = useKartutsnittInfo(ident);

  const { data: eiendomInfo } = useEiendomInfo(ident);

  if (isLoadingKartutsnitt || isLoadingKartutsnittInfo || isLoadingTeiger) {
    return (
      <Skeleton
        marginTop={{ base: 3, lg: 0 }}
        height="260px"
        width="380px"
        display="block"
      />
    );
  }

  if (kartutsnittError || kartutsnittInfoError || teigerError != undefined) {
    return (
      <Box height="260px" width="380px">
        <Alert status="error" marginBottom={2}>
          <AlertIcon />
          <AlertTitle>Kunne ikke hente kartutsnitt</AlertTitle>
        </Alert>
      </Box>
    );
  }

  if (
    (teiger.length === 0 && eiendomInfo?.type !== "Seksjon") ||
    eiendomInfo?.type === "Anleggseiendom"
  ) {
    return null;
  }

  return (
    <Box marginTop={{ base: 3, lg: 0 }}>
      <LinkBox>
        <Box position="relative">
          <Image src={kartutsnitt} alt="kartutsnitt" />
          <LinkOverlay
            href={`https://norgeskart.no/${getKartutsnittLenke(
              kartutsnittInfo.koordinater,
            )}`}
            isExternal
          >
            <Button
              rightIcon="open_in_new"
              colorScheme="blue"
              position="absolute"
              right={2}
              bottom={2}
            >
              Vis i kart
            </Button>
          </LinkOverlay>
        </Box>
      </LinkBox>
    </Box>
  );
};
