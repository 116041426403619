import {
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@kvib/react";
import { ReactElement } from "react";

interface Props {
  caption: string;
  headers: ReactElement[];
  rows: ReactElement[][];
}

const PanelTable = ({ headers, rows, caption }: Props) => {
  return (
    <TableContainer whiteSpace="normal">
      <Table variant="simple">
        <TableCaption>{`${caption} på eiendommen`}</TableCaption>
        <Thead>
          <Tr>
            {headers.map((header, i) => (
              <Th px="4" key={i}>
                {header}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {rows.map((row, i) => (
            <Tr key={i}>
              {row.map((content, i) => (
                <Td paddingInline="4" key={i}>
                  {content}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default PanelTable;
