import { AxiosError } from "axios";
import useSWRImmutable from "swr/immutable";

import {Driftsmelding, DriftsmeldingState} from "./apiDataTypes";
import { getBaseUrl } from "./config";

export const useGetDriftsmeldinger = (): DriftsmeldingState => {
    const driftsmeldingUrl = `${getBaseUrl()}/api/driftsmelding`;

    const { data, error } = useSWRImmutable<Driftsmelding[], AxiosError>(driftsmeldingUrl);

    return {
        data,
        error,
        loading: !error && !data,
    };
};
