import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  defaultFeatureFlags,
  FeatureFlags,
  fetchFeatureFlags,
} from "../utils/fetchFeatureFlags";

const useFeatureFlags = () => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadFeatureFlags = async () => {
      const flags = await fetchFeatureFlags();
      setFeatureFlags(flags);
      setLoading(false);
    };

    void loadFeatureFlags();
  }, []);

  return { featureFlags, loading };
};

interface FeatureFlagsProviderProps {
  children: ReactNode;
}

const FeatureFlagsContext = createContext<FeatureFlags | null>(null);

export const FeatureFlagsProvider = ({
  children,
}: FeatureFlagsProviderProps) => {
  const { featureFlags, loading } = useFeatureFlags();

  return (
    <FeatureFlagsContext.Provider
      value={!loading ? featureFlags : defaultFeatureFlags}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useFeatureFlagsContext = (): FeatureFlags => {
  const context = useContext(FeatureFlagsContext);
  if (context === null) {
    throw new Error(
      "useFeatureFlagsContext must be used within a FeatureFlagsProvider",
    );
  }

  return context;
};
