import { Box, Flex, Heading } from "@kvib/react";

type Props = {
  size: "lg" | "md";
};

const EiendomIcon = ({ size }: Props) => {
  const boxDimensions = size === "lg" ? ["10", "10", "12"] : ["6", "6", "8"];

  return (
    <Flex
      color="green.500"
      width="container.md"
      alignItems="baseline"
      maxWidth="100%"
      gap={["2", "2", "4"]}
    >
      <Box height={boxDimensions}>
        <svg
          aria-labelledby="se_eiendom_logo"
          height="100%"
          viewBox="0 0 57 44"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title id="se_eiendom_logo">Se eiendom logo</title>
          <g
            id="Icons"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="Icon/Kartverket/Eiendom">
              <g id="create-24px">
                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
              </g>
              <g id="Icon_eiendom">
                <g>
                  <path
                    d="M0.9,44 L0.9,10.9 L13.9,0 L26.2,10.8 L26.2,40.4 L31.2,40.4 L31.2,26.3 L44.9,14 L57,24.6 L57,44 L42.6,44 L42.6,30.4 L34.6,30.4 L34.6,44 L11.5,44 L11.5,31 L5.7,31 L5.7,44 L0.9,44 Z M11.5,16.4 L5.6,16.4 L5.6,25 L11.5,25 L11.5,16.4 Z M22.8,16.4 L16.9,16.4 L16.9,25 L22.8,25 L22.8,16.4 Z M22.8,30.5 L16.9,30.5 L16.9,40.7 L22.8,40.7 L22.8,30.5 Z M53.6,30.5 L47.7,30.5 L47.7,40.7 L53.6,40.7 L53.6,30.5 Z"
                    id="Shape"
                    fill="var(--kvib-colors-green-500"
                    fillRule="nonzero"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </Box>
      <Heading as="h1" size={size == "lg" ? "xl" : "lg"}>
        Se eiendom
      </Heading>
    </Flex>
  );
};

export default EiendomIcon;
