import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@kvib/react";

type ErrorModalProps = {
  errorMessage: {
    body: string;
    heading: string;
    onClose: () => void;
  };
  isOpen: boolean;
};

const ErrorModal = ({ isOpen, errorMessage }: ErrorModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={errorMessage.onClose}>
      <ModalOverlay />
      <ModalContent marginInline="4">
        <ModalHeader>{errorMessage?.heading}</ModalHeader>
        <ModalBody whiteSpace="pre-line">{errorMessage.body}</ModalBody>
        <ModalFooter>
          <Button onClick={errorMessage.onClose}>OK</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ErrorModal;
