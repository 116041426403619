import { Box, HStack, Link, Select, Text } from "@kvib/react";
import { Bygning } from "api/apiDataTypes";
import { useBygninger } from "api/useBygninger";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  AccordionPanelWrapper,
  AccordionPanelWrapperError,
  AccordionPanelWrapperSkeltonLoader,
} from "shared/components/AccordionPanelWrapper";
import { InfoPopover } from "shared/components/InfoButton";
import PanelData from "shared/components/PanelData";

export const BygningerPanel: React.FC = () => {
  const ident = useParams();
  const { data: bygninger, error, isLoading } = useBygninger(ident);
  const [bygningstype, setBygningstype] = React.useState<string | null>(null);

  const title = `Bygninger (${bygninger?.length ?? 0})`;

  const bygningtyper = Array.from(
    new Set(
      bygninger?.map((bygning) => bygning.type).filter((type) => type !== ""),
    ),
  );

  const headers = [
    <HStack key="1">
      <b>Bygningsnummer</b>
      <InfoPopover color="blue">
        <Text>
          Unikt nummer på bygningen. Bygningsendringer har i tillegg et
          løpenummer.
        </Text>
      </InfoPopover>
    </HStack>,
    <HStack key="2">
      <b>Type</b>
      <InfoPopover color="blue">
        <Text>
          Bygningstype: Formålet bygget er registrert eller godkjent for.
        </Text>
      </InfoPopover>
    </HStack>,
    <HStack key="3">
      <b>Status</b>
      <InfoPopover color="blue">
        <Text>
          Bygningsstatus: Byggets status, enten registrert status eller status i
          en byggesaksprosess.
        </Text>
      </InfoPopover>
    </HStack>,
    <HStack key="4">
      <b>Sefrak</b>
      <InfoPopover color="blue">
        <Text>
          SEFRAK er et landsdekkende register over eldre bygninger og andre
          kulturminner.{" "}
          <Link href="https://www.riksantikvaren.no/les-om/sefrak/" isExternal>
            Les mer på Riksantikvarens nettsider
          </Link>{" "}
        </Text>
      </InfoPopover>
    </HStack>,
    <HStack key="5">
      <b>Fredet</b>
      <InfoPopover color="blue">
        <Text>
          <Link href="https://www.riksantikvaren.no/Fredning" isExternal>
            Riksantikvaren
          </Link>{" "}
          har ansvar for fredning av kulturminner. Se eventuelt flere detaljer
          under fanen Kulturminner.
        </Text>
      </InfoPopover>
    </HStack>,
    <HStack key="6">
      <b>Næringsgruppe</b>
    </HStack>,
    <HStack key="7">
      <b>Bygningsendring</b>
      <InfoPopover color="blue">
        <Text>Type tilbygg eller bygningsendring.</Text>
      </InfoPopover>
    </HStack>,
  ];

  const rowElements = (bygning: Bygning) => [
    <Box key="1">{bygning.bygningsnummer}</Box>,
    <Box key="2">{bygning.type}</Box>,
    <Box key="3">{bygning.status}</Box>,
    <Box key="4">{bygning.isSefrak ? "Ja" : "Nei"}</Box>,
    <Box key="5">{bygning.isFredet ? "Ja" : "Nei"}</Box>,
    <Box key="6">{bygning.naeringsgruppe}</Box>,
    <Box key="7">{bygning.bygningsendring}</Box>,
  ];

  const rows = useMemo(() => {
    return (
      bygninger
        ?.filter(({ type }) => !bygningstype || type === bygningstype)
        .map((bygning) => {
          return {
            title: bygning.bygningsnummer,
            subtitle: bygning.type.split("-").at(1) ?? "Annet",
            data: rowElements(bygning),
          };
        }) ?? []
    );
  }, [bygningstype, bygninger]);

  if (isLoading) {
    return <AccordionPanelWrapperSkeltonLoader title="Bygninger" />;
  }

  if (error) {
    return (
      <AccordionPanelWrapperError
        title="Bygninger"
        errorText="Kunne ikke hente bygninger"
      />
    );
  }

  if (bygninger?.length === 0) {
    return (
      <AccordionPanelWrapper title={title}>
        Fant ingen bygninger.
      </AccordionPanelWrapper>
    );
  }

  return (
    <AccordionPanelWrapper title={title}>
      <Select
        placeholder="Alle"
        onChange={(e) => setBygningstype(e.target.value)}
      >
        {bygningtyper.map((type) => (
          <option key={type} value={type}>
            {type}
          </option>
        ))}
      </Select>
      <PanelData caption="Bygninger" headers={headers} rows={rows} />
    </AccordionPanelWrapper>
  );
};
