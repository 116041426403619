import { HStack, Text, VStack } from "@kvib/react";
import React, { ChangeEvent } from "react";
import { Form, useSearchParams } from "react-router-dom";

import {PRIS_PR_PRODUKT} from "../../../shared/constants";
import Bestillingoversikt from "../Bestillingoversikt/Bestillingoversikt";
import BestillingskjemaGrunnbokBorettslag from "./BestillingskjemaGrunnbokBorettslag/BestillingskjemaGrunnbokBorettslag";
import BestillingskjemaGrunnbokFastEiendom from "./BestillingskjemaGrunnbokFastEiendom/BestillingskjemaGrunnbokFastEiendom";
import BestillingskjemaPantebok from "./BestillingskjemaPantebok/BestillingskjemaPantebok";
import BestillingskjemaQuestion from "./BestillingskjemaQuestion/BestillingskjemaQuestion";

const Bestillingskjema = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const documentType = searchParams.get("documentType") ?? "";
  const historisk = searchParams.get("historisk") ?? "";
  const nynorsk = searchParams.get("nynorsk") ?? "";
  const isBorettslagsandel = searchParams.get("isBorettslagsandel") ?? "";

  type BestillingskjemaState = {
    documentType: string;
    historisk: string;
    nynorsk: string;
    isBorettslagsandel: string;
  };

  function filterEmpty(obj: BestillingskjemaState) {
    return (Object.keys(obj) as Array<keyof BestillingskjemaState>).reduce(
      (acc, key) => {
        if (obj[key] !== "") {
          acc[key] = obj[key];
        }

        return acc;
      },
      {} as Partial<BestillingskjemaState>,
    );
  }

  const onChange = (e: ChangeEvent<HTMLFormElement>) => {
    const shouldResetForm = e.target.name === "documentType";

    setSearchParams(
      filterEmpty({
        documentType,
        historisk: shouldResetForm ? "" : historisk,
        nynorsk: shouldResetForm ? "" : nynorsk,
        isBorettslagsandel: shouldResetForm ? "" : isBorettslagsandel,
        [e.target.name]: e.target.value,
      }),
    );
  };

  return (
    <HStack
      width="100%"
      justifyContent="space-between"
      alignItems="flex-start"
      gap={30}
    >
      <VStack alignItems="flex-start">
        <Form onChange={onChange}>
          <VStack alignItems="flex-start" gap={10}>
            <VStack alignItems="flex-start">
              <Text fontSize="4xl" fontWeight="bold">
                Bestill eiendomsdokumenter
              </Text>
              <Text maxWidth="550px">
                Her kan du bestille en utskrift av grunnboken, som viser deg en
                oversikt over hvilke dokumenter som er tinglyst på en eiendom.
                Du kan også bestille kopi av dokumentene som er tinglyst etter
                1951.
              </Text>
            </VStack>

            <BestillingskjemaQuestion
              name="documentType"
              value={documentType}
              question={{
                title: "Hva ønsker du å bestille?",
                answers: [
                  {
                    label: "Kopi av tinglyst dokument",
                    value: "pantebok",
                    price: PRIS_PR_PRODUKT.toString(),
                  },
                  {
                    label: "Bekreftet grunnboksutskrift",
                    value: "grunnbok",
                    price: PRIS_PR_PRODUKT.toString(),
                  },
                ],
              }}
            />

            <BestillingskjemaQuestion
              isShowing={documentType === "grunnbok"}
              name="historisk"
              value={historisk}
              question={{
                title: "Ønsker du en aktiv eller historisk grunnboksutskrift?",
                answers: [
                  { label: "Aktiv", value: "false" },
                  { label: "Historisk", value: "true" },
                ],
              }}
            />

            <BestillingskjemaQuestion
              isShowing={documentType === "grunnbok" && historisk !== ""}
              name="nynorsk"
              value={nynorsk}
              question={{
                title: "Ønsker du en bokmål eller nynorsk grunnboksutskrift?",
                answers: [
                  { label: "Bokmål", value: "false" },
                  { label: "Nynorsk", value: "true" },
                ],
              }}
            />

            <BestillingskjemaQuestion
              isShowing={
                documentType === "grunnbok" &&
                historisk !== "" &&
                nynorsk !== ""
              }
              name="isBorettslagsandel"
              value={isBorettslagsandel}
              question={{
                title: "Er det en fast eiendom eller en borettslagsandel?",
                answers: [
                  { label: "Fast eiendom", value: "false" },
                  { label: "Borettslagsandel", value: "true" },
                ],
              }}
            />

            {documentType === "pantebok" && <BestillingskjemaPantebok />}
            {documentType === "grunnbok" && isBorettslagsandel == "true" && (
              <BestillingskjemaGrunnbokBorettslag />
            )}
            {documentType === "grunnbok" && isBorettslagsandel == "false" && (
              <BestillingskjemaGrunnbokFastEiendom />
            )}
          </VStack>
        </Form>
      </VStack>
      <Bestillingoversikt />
    </HStack>
  );
};

export default Bestillingskjema;
