import { HStack, Text, VStack } from "@kvib/react";

import { TinglystDokumentBestilling } from "../../../shared/bestillingTypes";
import { PRIS_PR_PRODUKT } from "../../../shared/constants";
import { formatNorwegianDate } from "../../../shared/utils/formatUtils";

type Props = {
  tinglystDokumentBestilling: TinglystDokumentBestilling;
};

const BestillPantebokDisplayObject = ({
  tinglystDokumentBestilling,
}: Props) => {
  const { dokumentaar, dokumentnummer, embetenummer } =
    tinglystDokumentBestilling.dokumentIdent;
  const tinglystDato = tinglystDokumentBestilling.tinglystDato;

  return (
    <HStack width="100%" justifyContent="space-between">
      <VStack alignItems="flex-start">
        <Text fontWeight="bold">
          {dokumentaar}/{dokumentnummer}/{embetenummer}
        </Text>
        <Text>Tinglyst: {formatNorwegianDate(tinglystDato)}</Text>
      </VStack>
      <Text>{PRIS_PR_PRODUKT},-</Text>
    </HStack>
  );
};

export default BestillPantebokDisplayObject;
