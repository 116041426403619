import {SearchResponse } from "api/apiDataTypes";
import { getBaseUrl } from "api/config";
import {AxiosError} from "axios";
import { useState } from "react";
import useSWRImmutable from "swr/immutable";

const baseUrl = getBaseUrl();

export const useGetEiendommer = (initialQuery: string) => {
  const [fetchQuery, setFetchQuery] = useState(initialQuery);

  const url = `${baseUrl}/api/soekEtterEiendom?searchstring=${fetchQuery}`;

  const { data, error, isLoading } = useSWRImmutable<SearchResponse, AxiosError>(
    fetchQuery ? url : null
  );

  return {
    data,
    error,
    setFetchQuery,
    fetchQuery,
    isLoading,
  };
};
