import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Divider,
  HStack,
  Input,
  Text,
  VStack,
} from "@kvib/react";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  BestillingType,
  GrunnboksutskriftBestillingBorettslag,
} from "../../../../shared/bestillingTypes";
import {PRIS_PR_PRODUKT} from "../../../../shared/constants";
import { useBestillingerContext } from "../../../../shared/context/BestillingerContext";
import { validateOrgNumber } from "../../../../shared/utils/validateOrgNumber";

const BestillingskjemaGrunnbokBorettslag = () => {
  const { isBestillingInList, addBestilling } = useBestillingerContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const [organisasjonsnummer, setOrganisasjonsnummer] = useState(
    searchParams.get("organisasjonsnummer") ?? "",
  );
  const [andelsnummerString, setAndelsnummerString] = useState(
    searchParams.get("andelsnummer") ?? "",
  );

  const isNynorsk = searchParams.get("nynorsk") === "true";
  const isHistorisk = searchParams.get("historisk") === "true";

  const isValidOrganisasjonsnummer = validateOrgNumber(organisasjonsnummer);

  const isValidAndelsnummer = andelsnummerString.match(/^\d+$/);
  const isValid = isValidOrganisasjonsnummer && isValidAndelsnummer;

  const currentBestilling: GrunnboksutskriftBestillingBorettslag | undefined =
    (() => {
      if (!isValidOrganisasjonsnummer || !isValidAndelsnummer) {
        return undefined;
      }

      return {
        bestillingType: BestillingType.GRUNNBOK_BORETTSLAG,
        historisk: isHistorisk,
        nynorsk: isNynorsk,
        borettslagandelIdent: {
          borettslagnummer: organisasjonsnummer,
          andelsnummer: parseInt(andelsnummerString),
        },
      };
    })();

  const isAlreadyInList =
    currentBestilling && isBestillingInList(currentBestilling);

  const alertBox = (status: "error" | "warning" | "info", message: string) => {
    return (
      <Alert status={status} width="100%">
        <AlertIcon />
        <AlertTitle>{message}</AlertTitle>
      </Alert>
    );
  };

  const handleAddBestilling = () => {
    if (currentBestilling && !isAlreadyInList) {
      addBestilling(currentBestilling);
    }

    setSearchParams({});
  };

  return (
    <>
      <HStack>
        <VStack alignItems="flex-start">
          <Text fontSize="xl" fontWeight="bold">
            Organisasjonsnummer
          </Text>
          <Input
            name="organisasjonsnummer"
            value={organisasjonsnummer}
            type="number"
            onChange={(e) => setOrganisasjonsnummer(e.target.value)}
            placeholder='Eksempel "950385528"'
            isInvalid={
              !isValidOrganisasjonsnummer && organisasjonsnummer !== ""
            }
            width={300}
            size="lg"
          />
        </VStack>
        <VStack alignItems="flex-start">
          <Text fontSize="xl" fontWeight="bold">
            Andelsnummer
          </Text>
          <Input
            name="andelsnummer"
            value={andelsnummerString}
            onChange={(e) => setAndelsnummerString(e.target.value)}
            placeholder='Eksempel "5"'
            type="number"
            isInvalid={!isValidAndelsnummer && andelsnummerString !== ""}
            width={300}
            size="lg"
          />
        </VStack>
      </HStack>
      {(organisasjonsnummer !== "" || andelsnummerString !== "") && (
        <>
          <Divider />
          <VStack alignItems="flex-start" gap={10}>
            {!isValidOrganisasjonsnummer &&
              organisasjonsnummer !== "" &&
              alertBox("error", "Organisasjonsnummeret er ugyldig.")}
            {!isValidAndelsnummer &&
              andelsnummerString !== "" &&
              alertBox("error", "Andelsnummeret er ugyldig.")}
            {isValid && (
              <>
                <HStack width="100%" gap={100}>
                  <VStack alignItems="flex-start">
                    <Text fontWeight="bold">Borettslagsandel</Text>
                    <Text>
                      {organisasjonsnummer}-{andelsnummerString}
                    </Text>
                  </VStack>
                  <Text>{PRIS_PR_PRODUKT},-</Text>
                  <Button
                    onClick={handleAddBestilling}
                    isDisabled={isAlreadyInList}
                    leftIcon="add"
                    variant="secondary"
                  >
                    Legg til
                  </Button>
                </HStack>
                {isAlreadyInList &&
                  alertBox(
                    "info",
                    "Dokumentet er allerede lagt til i bestillingen.",
                  )}
                {!isAlreadyInList &&
                  alertBox(
                    "warning",
                    "Vi sjekker ikke om borettslagsandelen finnes.",
                  )}
              </>
            )}
          </VStack>
        </>
      )}
    </>
  );
};

export default BestillingskjemaGrunnbokBorettslag;
