import { HStack, Input, Text } from "@kvib/react";
import React from "react";

import useOrgnrSearch from "../../../api/useOrgnrSearch";

const OrgnrSearchField = () => {
  const { orgnr, orgnavn, errorMsg, handleOrgnrChange } = useOrgnrSearch();

  return (
      <HStack width="100%">
        <Input
            type="number"
            width="200px"
            value={orgnr}
            onChange={(e) => handleOrgnrChange(e.target.value)}
        />
        {orgnavn && (
            <Text alignSelf="center" fontSize="sm">
              {orgnavn}
            </Text>
        )}
        {errorMsg && (
            <Text alignSelf="center" color="yellow.800" fontSize="sm">
              {errorMsg}
            </Text>
        )}
      </HStack>
  );
};

export default OrgnrSearchField;