import { useEffect, useState } from "react";
import { DokumentMetadata } from "./apiDataTypes";
import useSWRImmutable from "swr/immutable";
import { AxiosError } from "axios";
import { getBaseUrl } from "./config";

const useDokumentMetadata = (dokumentAar: number, dokumentNummer: number, embeteNummer: number) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/api/bestill/pantebok/metadata/${dokumentAar}/${dokumentNummer}/${embeteNummer}`;
  const [dokumentData, setDokumentData] = useState<
    DokumentMetadata | undefined
  >();

  const { data, error } = useSWRImmutable<DokumentMetadata, AxiosError>(url);

  useEffect(() => {
    setDokumentData(data);
    if (error && error.status !== 404) {
      console.error(error);
    }
  }, [data, error]);

  return dokumentData;
};

export default useDokumentMetadata;
