import { Box, Button, HStack, Text, VStack } from "@kvib/react";
import React from "react";
import { Form, Link, useSearchParams } from "react-router-dom";

import Bestillingoversikt from "../Bestillingoversikt/Bestillingoversikt";
import LeveringOgBetalingBedrift from "./LeveringOgBetalingBedrift";
import LeveringOgBetalingPrivat from "./LeveringOgBetalingPrivat";

const BestillLeveringOgBetaling = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const kundetype = searchParams.get("kundetype") || "privat";

  if (kundetype === "privat") {
    searchParams.delete("orgnr");
    searchParams.delete("refnr");
  }

  return (
    <HStack
      width="100%"
      justifyContent="space-between"
      alignItems="flex-start"
      gap={30}
    >
      <VStack alignItems="flex-start">
        <Form>
          <VStack alignItems="flex-start" gap={10}>
            <Text fontSize="4xl" fontWeight="bold">
              Levering og betaling
            </Text>
            <Link to="/bestill">
              <Button leftIcon="arrow_left_alt" variant="tertiary">
                Gå tilbake og bestill mer
              </Button>
            </Link>
            <VStack gap={2} alignItems="flex-start" width="450px">
              <HStack gap={10}>
                <Box
                  borderBottom={`3px solid ${kundetype === "privat" ? "green" : "gray"}`}
                >
                  <Button
                    fontSize="2xl"
                    leftIcon="person"
                    variant="tertiary"
                    color={kundetype === "privat" ? "black" : "gray"}
                    marginX={1}
                    onClick={() => setSearchParams({ kundetype: "privat" })}
                  >
                    Privat
                  </Button>
                </Box>
                <Box
                  borderBottom={`3px solid ${kundetype === "bedrift" ? "green" : "gray"}`}
                >
                  <Button
                    fontSize="2xl"
                    leftIcon="source_environment"
                    variant="tertiary"
                    color={kundetype === "bedrift" ? "black" : "gray"}
                    marginX={2}
                    onClick={() => setSearchParams({ kundetype: "bedrift" })}
                  >
                    Bedrift
                  </Button>
                </Box>
              </HStack>
              {kundetype === "privat" && <LeveringOgBetalingPrivat />}
              {kundetype === "bedrift" && <LeveringOgBetalingBedrift />}
            </VStack>
          </VStack>
        </Form>
      </VStack>
      <Bestillingoversikt />
    </HStack>
  );
};

export default BestillLeveringOgBetaling;
