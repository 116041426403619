import { HStack, Text, VStack } from "@kvib/react";

import { GrunnboksutskriftBestillingFastEiendom } from "../../../shared/bestillingTypes";
import { PRIS_PR_PRODUKT } from "../../../shared/constants";

type Props = {
  grunnboksutskriftBestillingFastEiendom: GrunnboksutskriftBestillingFastEiendom;
};

const BestillGrunnbokFastEiendomDisplayObject = ({
  grunnboksutskriftBestillingFastEiendom,
}: Props) => {
  const { matrikkelIdent, nynorsk, historisk } =
    grunnboksutskriftBestillingFastEiendom;
  const {
    kommunenummer,
    gaardsnummer,
    bruksnummer,
    festenummer,
    seksjonsnummer,
  } = matrikkelIdent;

  return (
    <HStack width="100%" justifyContent="space-between">
      <VStack alignItems="flex-start">
        <Text fontWeight="bold">
          {kommunenummer}-{gaardsnummer}/{bruksnummer}/{festenummer}/
          {seksjonsnummer}
        </Text>
        <Text> Fast eiendom </Text>
        <Text> {historisk ? "Historisk" : "Aktiv"} grunnboksutskrift </Text>
        <Text> Språk: {nynorsk ? "Nynorsk" : "Bokmål"} </Text>
      </VStack>
      <Text>{PRIS_PR_PRODUKT},-</Text>
    </HStack>
  );
};

export default BestillGrunnbokFastEiendomDisplayObject;
