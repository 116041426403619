import { AxiosError } from "axios";
import useSWRImmutable from "swr/immutable";

import {
  KoordinaterForKartutsnitt,
  PartialIdent,
  ServerState,
} from "./apiDataTypes";
import { getBaseUrl } from "./config";

export const useKartutsnittInfo = (
  ident: PartialIdent,
): ServerState<KoordinaterForKartutsnitt> => {
  const url = () => {
    const baseApiUrl = `${getBaseUrl()}/api/kartutsnitt/`;
    const params = `${ident.kommunenr}/${ident.gaardsnr}/${ident.bruksnr}/${
      ident?.festenr ?? 0
    }/${ident?.seksjonsnr ?? 0}`;
    return `${baseApiUrl}${params}`;
  };

  const { data, error } = useSWRImmutable<
    KoordinaterForKartutsnitt,
    AxiosError
  >(url());

  if (url == undefined) {
    return {
      data: undefined,
      error: new Error("Ugyldig matrikkelenhetident"),
      isLoading: false,
    };
  } else if (error != undefined) {
    return { data: undefined, error, isLoading: false };
  } else if (data != undefined) {
    return { data, error: undefined, isLoading: false };
  } else {
    return { data: undefined, error, isLoading: true };
  }
};
