import { Container, Heading, Link, Text, VStack } from "@kvib/react";

const NotFound = () => {
  return (
    <>
      <Container maxWidth="container.md" paddingTop="3">
        <Heading color="green.500"> Uff da, nå skjedde det noe galt...</Heading>
        <VStack alignItems="start" gap="3" paddingTop="5">
          <Text>Vi finner ikke siden du prøver å besøke, beklager!</Text>
          <Text>
            Du kan gå tilbake til <Link href="/">forsiden</Link> for å søke opp
            en eiendom, eller kontakte{" "}
            <Link href="mailto:post@kartverket.no">kundeservice</Link> dersom
            problemet vedvarer.
          </Text>
        </VStack>
      </Container>
    </>
  );
};

export default NotFound;
