import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

interface ReferanseContextType {
  referanse: string;
  setReferanse: (newReferanse: string) => void;
}

const ReferanseContext = createContext<ReferanseContextType>({
  referanse: "",
  setReferanse: () => {},
});

const useReferanse = () => {
  const [referanse, setReferanseState] = useState<string>("");

  useEffect(() => {
    try {
      const storedReferanse = localStorage.getItem("referanse");
      if (storedReferanse) {
        setReferanseState(storedReferanse);
      }
    } catch (error) {
      console.error("Failed to load referanse from localStorage:", error);
    }
  }, []);

  const setReferanse = (newReferanse: string) => {
    try {
      localStorage.setItem("referanse", newReferanse);
      setReferanseState(newReferanse);
    } catch (error) {
      console.error("Failed to save referanse to localStorage:", error);
    }
  };

  return {
    referanse,
    setReferanse,
  };
};

interface ReferanseProviderProps {
  children: ReactNode;
}

export const ReferanseProvider = ({ children }: ReferanseProviderProps) => {
  const { referanse, setReferanse } = useReferanse();

  return (
    <ReferanseContext.Provider value={{ referanse, setReferanse }}>
      {children}
    </ReferanseContext.Provider>
  );
};

export const useReferanseContext = () => {
  const context = useContext(ReferanseContext);
  if (!context) {
    throw new Error(
      "useReferanseContext must be used within a ReferanseProvider",
    );
  }

  return context;
};
