import React from "react";
import { useParams } from "react-router-dom";
import { ErrorPage } from "shared/components/ErrorPage";

import { EiendomInfoContent } from "./EiendomInfoContent";

export const EiendomInfo: React.FC = () => {
  const { kommunenr, gaardsnr, bruksnr } = useParams();

  if (gaardsnr && kommunenr && bruksnr) {
    return <EiendomInfoContent />;
  }

  return <ErrorPage></ErrorPage>;
};
