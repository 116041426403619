import { Box, HStack, Link, Select, Text } from "@kvib/react";
import { Kulturminne } from "api/apiDataTypes";
import { useKulturminner } from "api/useKulturminner";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  AccordionPanelWrapper,
  AccordionPanelWrapperError,
  AccordionPanelWrapperSkeltonLoader,
} from "shared/components/AccordionPanelWrapper";
import { InfoPopover } from "shared/components/InfoButton";
import PanelData from "shared/components/PanelData";

export const KulturminnerPanel = () => {
  const ident = useParams();
  const { data: kulturminner, isLoading, error } = useKulturminner(ident);

  const [kulturminnetype, setKulturminnetype] = React.useState<string | null>(
    null,
  );

  const getLokalitetUri = (lokalitetsnummer: string) => {
    return `http://www.kulturminnesok.no/kulturminnesok/kulturminne/?LOK_ID=${lokalitetsnummer}`;
  };

  const title = `Kulturminner (${kulturminner?.length ?? 0})`;

  const kulturminneTyper = Array.from(
    new Set(kulturminner?.map((kulturminne) => kulturminne.kulturminneArt)),
  );

  const headers = [
    <HStack key="1">
      <b>Lokalitetsnummer</b>
      <InfoPopover color="blue">
        <Text>Riksantikvarens kulturminne-ID.</Text>
      </InfoPopover>
    </HStack>,
    <HStack key="2">
      <b>Bygningsnummer</b>
    </HStack>,
    <HStack key="3">
      <b>Kulturminneart</b>
    </HStack>,
    <HStack key="4">
      <b>Vernetype</b>
    </HStack>,
  ];

  const rows = useMemo(() => {
    const rowElements = (kulturminne: Kulturminne) => [
      <Box key="1">
        <Link href={getLokalitetUri(kulturminne.lokalitetsnummer)}>
          {kulturminne.lokalitetsnummer}
        </Link>
      </Box>,
      <Box key="2">{kulturminne.bygningsnummer}</Box>,
      <Box key="3">{kulturminne.kulturminneArt}</Box>,
      <Box key="4">{kulturminne.vernetype}</Box>,
    ];

    return (
      kulturminner
        ?.filter(
          ({ kulturminneArt }) =>
            !kulturminnetype || kulturminneArt === kulturminnetype,
        )
        .map((kulturminne) => ({
          title: kulturminne.lokalitetsnummer,
          subtitle: kulturminne.kulturminneArt,
          data: rowElements(kulturminne),
        })) ?? []
    );
  }, [kulturminner, kulturminnetype]);

  if (isLoading) {
    return <AccordionPanelWrapperSkeltonLoader title="Kulturminner" />;
  }

  if (error) {
    return (
      <AccordionPanelWrapperError
        title="Kulturminner"
        errorText="Kunne ikke hente kulturminner"
      />
    );
  }

  return (
    <AccordionPanelWrapper title={title}>
      <Select
        placeholder="Alle"
        onChange={(e) => setKulturminnetype(e.target.value)}
      >
        {kulturminneTyper.map((type) => (
          <option key={type} value={type}>
            {type}
          </option>
        ))}
      </Select>
      <PanelData caption="Kulturminner" headers={headers} rows={rows} />
    </AccordionPanelWrapper>
  );
};
