import { Container } from "@kvib/react";
import React from "react";

interface Props {
  children: React.ReactNode;
  maxWidth?: string;
  minHeight?: string;
}

export const GreenContainer = ({ maxWidth, minHeight, children }: Props) => {
  return (
    <Container
      backgroundColor="green.100"
      padding={["5", "7"]}
      borderRadius="5"
      maxWidth={maxWidth ?? "100%"}
      minHeight={minHeight}
    >
      {children}
    </Container>
  );
};
