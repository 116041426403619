import {
  Bestilling,
  GrunnboksutskriftBestillingBorettslag,
  GrunnboksutskriftBestillingFastEiendom,
  isGrunnboksutskriftBorettslagBestilling,
  isGrunnboksutskriftFastEiendomBestilling,
  isTinglystDokumentBestilling,
  TinglystDokumentBestilling,
} from "../shared/bestillingTypes";
import {
  GrunnbokInternBorettslagBestillingRequest,
  GrunnbokInternFastEiendomBestillingRequest,
  InternBestillingRequest,
  OrganisasjonBestiller,
  PantebokInternBestillingRequest,
  PrivatpersonBestiller,
} from "./bestillApiDataTypes";
import { getBaseUrl } from "./config";

export const sendBestilling = async (
  bestillinger: Bestilling[],
  bestiller: PrivatpersonBestiller | OrganisasjonBestiller,
  referanse: string,
) => {
  const fastEiendomBestillinger: GrunnbokInternFastEiendomBestillingRequest[] =
    bestillinger
      .filter(isGrunnboksutskriftFastEiendomBestilling)
      .map((bestilling: GrunnboksutskriftBestillingFastEiendom) => ({
        bestiller: bestiller,
        nynorsk: bestilling.nynorsk,
        historisk: bestilling.historisk,
        referanse: referanse,
        matrikkelIdent: bestilling.matrikkelIdent,
      }));

  const borettslagBestillinger: GrunnbokInternBorettslagBestillingRequest[] =
    bestillinger
      .filter(isGrunnboksutskriftBorettslagBestilling)
      .map((bestilling: GrunnboksutskriftBestillingBorettslag) => ({
        bestiller: bestiller,
        nynorsk: bestilling.nynorsk,
        historisk: bestilling.historisk,
        borettslagandelIdent: bestilling.borettslagandelIdent,
        referanse: referanse,
      }));

  const pantebokBestillinger: PantebokInternBestillingRequest[] = bestillinger
    .filter(isTinglystDokumentBestilling)
    .map((bestilling: TinglystDokumentBestilling) => ({
      referanse: referanse,
      dokumentIdent: bestilling.dokumentIdent,
    }));

  const bestillingerRequest: InternBestillingRequest = {
    bestillingForAlleBestiller: bestiller,
    fastEiendomBestillinger: fastEiendomBestillinger,
    borettslagBestillinger: borettslagBestillinger,
    pantebokBestillinger: pantebokBestillinger,
  };

  const response = await fetch(`${getBaseUrl()}/api/bestill`, {
    method: "POST",
    body: JSON.stringify(bestillingerRequest),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Kunne ikke sende bestilling");
  }

  return response.text();
};
