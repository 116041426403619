import { AxiosError } from "axios";
import useSWRImmutable from "swr/immutable";

import { PartialIdent, ServerState } from "./apiDataTypes";
import { buildUrl } from "./helpers";

export function useGetByIdent<T>(
  { kommunenr, gaardsnr, bruksnr, festenr, seksjonsnr }: PartialIdent,
  path: string,
): ServerState<T> {
  const missingParams = !kommunenr || !gaardsnr || !bruksnr;

  const url = !missingParams
    ? buildUrl(path, kommunenr, gaardsnr, bruksnr, festenr, seksjonsnr)
    : undefined;

  const { data, error } = useSWRImmutable<T, AxiosError>(url);

  if (url == undefined) {
    return {
      data: undefined,
      error: new Error("Ugyldig matrikkelenhetident"),
      isLoading: false,
    };
  } else if (error != undefined) {
    return { data: undefined, error, isLoading: false };
  } else if (data != undefined) {
    return { data, error: undefined, isLoading: false };
  } else {
    return { data: undefined, error, isLoading: true };
  }
}
