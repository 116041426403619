import { Accordion, Heading } from "@kvib/react";
import { useBorettslag } from "api/useBorettslag";
import { useEiendomInfo } from "api/useEiendomInfo";
import { AdresserPanel } from "features/EiendomInfo/MatrikkelInfo/Adresser/AdresserPanel";
import { BygningerPanel } from "features/EiendomInfo/MatrikkelInfo/Bygninger/BygningerPanel";
import { EiendomPanel } from "features/EiendomInfo/MatrikkelInfo/Eiendom/EiendomPanel";
import { FestegrunnerPanel } from "features/EiendomInfo/MatrikkelInfo/Festegrunner/FestegrunnerPanel";
import { KulturminnerPanel } from "features/EiendomInfo/MatrikkelInfo/Kulturminner/KulturminnerPanel";
import { TeigerPanel } from "features/EiendomInfo/MatrikkelInfo/Teiger/TeigerPanel";
import React from "react";
import { useParams } from "react-router-dom";

import { BorettslagPanel } from "./Borettslag/BorettslagPanel";
import { SeksjonerPanel } from "./Seksjoner/SesksjonerPanel";

export const MatrikkelInfo: React.FC = () => {
  const ident = useParams();
  const { data: eiendomInfo } = useEiendomInfo(ident);
  const { data: borettslag } = useBorettslag(ident);

  return (
    <section>
      <Heading as="h2" size="lg" marginTop={10} marginBottom={4}>
        Informasjon fra matrikkelen
      </Heading>
      <Accordion allowMultiple>
        <EiendomPanel />
        {borettslag?.borettslag && <BorettslagPanel />}
        {eiendomInfo?.isSeksjonert && <SeksjonerPanel />}
        <AdresserPanel />
        <BygningerPanel />
        <TeigerPanel />
        {eiendomInfo?.isFestegrunner && <FestegrunnerPanel />}
        {eiendomInfo?.isKulturminner && <KulturminnerPanel />}
      </Accordion>
    </section>
  );
};
