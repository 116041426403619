export const validateOrgNumber = (orgNumber: string): boolean => {
  const orgNumberPattern = /^\d{9}$/;
  if (!orgNumberPattern.test(orgNumber)) {
    return false;
  }

  // Weights assigned to the first 8 digits
  const weights = [3, 2, 7, 6, 5, 4, 3, 2];
  const digits = orgNumber.split("").map(Number);

  let total = 0;
  for (let i = 0; i < weights.length; i++) {
    total += weights[i] * digits[i];
  }

  const remainder = total % 11;
  let controlDigit = 11 - remainder;

  if (controlDigit === 11) {
    controlDigit = 0;
  }

  // If control digit is 10, the organization number is invalid
  return !(controlDigit === 10 || controlDigit !== digits[8]);
};
