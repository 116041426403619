import { useAuthenticationFlow } from "@kartverket/frontend-aut-lib";
import useSWRImmutable from "swr/immutable";

const useBlobFetcher = (url: string) => {
  const { tokenHolderFunc } = useAuthenticationFlow();

  const fetcher = (url: string) =>
    fetch(url, {
      headers: {
        Authorization: `Bearer ${tokenHolderFunc()?.token ?? "Invalid_Token"}`,
      },
    }).then((res) => {
      if (res.status === 429) {
        throw new Error("Too many requests");
      } else if (res.status === 500) {
        throw new Error(
          "Vi greide ikke hente grunnboksutskriften. Dette kan skyldes at vi har tekniske problemer, eller at det er registrert feil informasjon i matrikkelen. Vennligst kontakt kundeservice dersom det fortsetter å være et problem.",
        );
      }

      return res.blob();
    });

  return useSWRImmutable(url, fetcher);
};

export default useBlobFetcher;
