import { Button, Text, VStack } from "@kvib/react";
import React from "react";
import { Link } from "react-router-dom";

import BestillingoversiktKvittering from "./BestillingoversiktKvittering";

const BestillKvittering = () => {
  return (
    <VStack gap="28px">
      <VStack width="100%" alignItems="flex-start" gap={10}>
        <Link to="/bestill">
          <Button leftIcon="arrow_left_alt" variant="tertiary">
            Gå tilbake og bestill mer
          </Button>
        </Link>
      </VStack>
      <VStack
        id="bestill-kvittering"
        width="470px"
        gap={30}
        alignItems="center"
        justifyContent="center"
        margin="auto"
        display="flex"
      >
        <Text fontSize="4xl">Bestillingen er sendt til behandling!</Text>
        <Text fontSize="xl">
          Forventet leveringstid er 1 virkedag. Din bestilling blir levert
          digitalt til din innboks på Se Eiendom. Du får beskjed via epost når
          bestillingen din er klar.
        </Text>
      </VStack>
      <BestillingoversiktKvittering />
      <Link to="https://seeiendom.kartverket.no/" target="_blank">
        <Button variant="tertiary" fontWeight="bold" rightIcon="open_in_new">
          Gå tilbake til seeiendom.kartverket.no
        </Button>
      </Link>
    </VStack>
  );
};

export default BestillKvittering;
