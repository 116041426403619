import { HStack, Icon, IconButton, Input, Text, VStack } from "@kvib/react";
import React from "react";
import { useSearchParams } from "react-router-dom";

import { useReferanseContext } from "../../../shared/context/ReferanseContext";
import OrgnrSearchField from "./OrgnrSearchField";

const LeveringOgBetalingBedrift = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setReferanse } = useReferanseContext();
  const referanse = searchParams.get("referanse") ?? "";

  return (
    <VStack width="100%" paddingTop="32px" gap="32px">
      {/* Organisasjon */}
      <VStack alignItems="flex-start" width="100%">
        <HStack alignItems="center">
          <Icon icon="source_environment" />
          <Text color="black" fontSize="l" fontWeight="bold">
            Organisasjonsnummer
          </Text>
        </HStack>
        <Text>Ni-sifret registreringsnummer til din organisasjon.</Text>
        <OrgnrSearchField />
      </VStack>

      {/* Levering */}
      <VStack alignItems="flex-start" width="100%">
        <HStack alignItems="center" fontSize="2xl">
          <Icon icon="mail" />
          <Text color="black">Altinn</Text>
        </HStack>
        <Text fontSize="lg">
          Digital levering på Altinn
          <IconButton icon="info" aria-label="icon-button" variant="tertiary" />
        </Text>
        <Text fontSize="lg">Forventet levering innen 1 virkedag</Text>
      </VStack>

      {/* Betaling */}
      <VStack alignItems="flex-start" width="100%">
        <HStack alignItems="center" fontSize="2xl">
          <Icon icon="wallet" />
          <Text color="black">Betalingsmåte</Text>
        </HStack>
        <Text fontSize="lg">Faktura til virksomheten</Text>
      </VStack>

      <VStack alignItems="flex-start" width="100%" marginTop="24px">
        <HStack alignItems="center">
          <Text color="black" fontSize="l" fontWeight="bold">
            Referanse (valgfritt)
          </Text>
        </HStack>
        <Text>Eget referansenummer til bestillingen</Text>
        <Input
          type="text"
          width="200px"
          value={referanse}
          onChange={(e) => {
            setSearchParams({
              ...Object.fromEntries(searchParams),
              referanse: e.target.value,
            });
            setReferanse(e.target.value);
          }}
        />
      </VStack>
    </VStack>
  );
};

export default LeveringOgBetalingBedrift;
