import styled from "@emotion/styled";
import { Box, useMediaQuery } from "@kvib/react";
import { Footer } from "features/Footer/Footer";
import { Header } from "features/Header/Header";
import React from "react";

import { ContentContainer } from "./ContentContainer";

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

interface Props {
  children: React.ReactNode;
}

export const AppLayout: React.FC<Props> = ({ children }) => {
  const [isAbove550px] = useMediaQuery("(min-width: 550px)");
  return (
    <AppContainer>
      <Header />
      <Box paddingTop={isAbove550px ? "30px" : "20px"} flex={1}>
        <ContentContainer>{children}</ContentContainer>
      </Box>
      <Footer />
    </AppContainer>
  );
};
