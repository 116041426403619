import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Text,
} from "@kvib/react";
import { Adresse } from "api/apiDataTypes";
import { getBaseUrl } from "api/config";
import { useAdresser } from "api/useAdresser";
import { useEiendomInfo } from "api/useEiendomInfo";
import { ReactElement, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  AccordionPanelWrapper,
  AccordionPanelWrapperError,
  AccordionPanelWrapperSkeltonLoader,
} from "shared/components/AccordionPanelWrapper";
import { InfoPopover } from "shared/components/InfoButton";
import PanelData from "shared/components/PanelData";

export const AdresserPanel = () => {
  const ident = useParams();
  const { data: eiendomInfo } = useEiendomInfo(ident);
  const { data: adresser, isLoading, error } = useAdresser(ident);

  const [query, setQuery] = useState("");

  const openAdressemerker = () => {
    const url = `${getBaseUrl()}/api/adressemerker/${eiendomInfo?.matrikkelenhetId}`;
    window.open(url);
  };

  const harAdresseMedBruksenhetsnummer = (adresser: Adresse[]) => {
    return adresser.some(
      (a) => a.adresse.search(".*-[A-Z][0-9][0-9][0-9][0-9]") > -1,
    );
  };

  const visSeksjoner =
    adresser?.some((adresse) => adresse.matrikkelenhetIdent) ?? false;

  const radElements = (adresse: Adresse): ReactElement[] => [
    <Box key={0}>{adresse.adresse}</Box>,
    <Box key={1}>
      {adresse.matrikkelenhetIdent ? (
        <Box>
          <Link
            //Kan komme to forskjellige responser fra backenden
            href={
              adresse.matrikkelenhetIdent.kommunenr
                ? `/eiendom/${adresse.matrikkelenhetIdent.kommunenr}/${adresse.matrikkelenhetIdent.gaardsnr}/${adresse.matrikkelenhetIdent.bruksnr}/${adresse.matrikkelenhetIdent.festenr}/${adresse.matrikkelenhetIdent.seksjonsnr}`
                : `/eiendom/${(
                    adresse.matrikkelenhetIdent as unknown as string
                  ).replace("-", "/")}`
            }
          >
            {adresse.matrikkelenhetIdent.kommunenr ? (
              <>
                {adresse.matrikkelenhetIdent.kommunenr}/
                {adresse.matrikkelenhetIdent.gaardsnr}/
                {adresse.matrikkelenhetIdent.bruksnr}/
                {adresse.matrikkelenhetIdent.festenr}/
                {adresse.matrikkelenhetIdent.seksjonsnr}
              </>
            ) : (
              <>{adresse.matrikkelenhetIdent}</>
            )}
          </Link>
        </Box>
      ) : (
        <Box />
      )}
    </Box>,
    <Box key={2}>{adresse.postnummeromraade}</Box>,
    <Box key={3}>{adresse.grunnkrets}</Box>,
    <Box key={4}>{adresse.kirkesokn}</Box>,
    <Box key={5}>{adresse.valgkrets}</Box>,
  ];

  const headers = useMemo(() => {
    const elements = [
      <HStack key={0}>
        <b>Adresse</b>
        <InfoPopover color="blue">
          <Text>
            Vegadresse eller matrikkeladresse, med eventuelt
            adressetilleggsnavn. Dersom det er flere leiligheter på adressen
            skal bruksenhetsnummeret brukes sammen med adressen.
          </Text>
        </InfoPopover>
      </HStack>,
      <HStack key={1}>
        <b>Eiendom koblet til bruksenheten</b>
      </HStack>,
      <HStack key={2}>
        <b>Postnummerområde</b>
      </HStack>,
      <HStack key={3}>
        <b>Grunnkrets</b>
        <InfoPopover color="blue">
          <Text>
            Mindre geografisk enhet, brukes av{" "}
            <Link href="http://www.ssb.no/" isExternal>
              Statistisk sentralbyrå
            </Link>{" "}
            til statistisk inndeling.
          </Text>
        </InfoPopover>
      </HStack>,
      <HStack key={4}>
        <b>Kirkesokn</b>
      </HStack>,
      <HStack key={5}>
        <b>Valgkrets</b>
      </HStack>,
    ];

    return visSeksjoner ? elements : [elements[0], ...elements.slice(2)];
  }, [visSeksjoner]);

  const rows = useMemo(() => {
    return (
      adresser
        ?.filter(({ adresse }) =>
          adresse.toLowerCase().includes(query.toLowerCase()),
        )
        .map((adresse) => {
          const row = { title: adresse.adresse, data: radElements(adresse) };
          return visSeksjoner
            ? row
            : { ...row, data: [row.data[0], ...row.data.slice(2)] };
        }) ?? []
    );
  }, [adresser, query, visSeksjoner]);

  if (isLoading || !adresser) {
    return <AccordionPanelWrapperSkeltonLoader title="Adresser" />;
  }

  if (error) {
    return (
      <AccordionPanelWrapperError
        title="Adresser"
        errorText="Kunne ikke hente adresser"
      />
    );
  }

  if (adresser.length === 0) {
    return (
      <AccordionPanelWrapper title={`Adresser (${adresser?.length})`}>
        Fant ingen adresser.
      </AccordionPanelWrapper>
    );
  }

  return (
    <AccordionPanelWrapper title={`Adresser (${adresser?.length})`}>
      {adresser.length !== undefined && adresser.length > 1 && (
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <span className="material-symbols-outlined">search</span>
          </InputLeftElement>
          <Input
            type="search"
            placeholder="Finn en spesifikk adresse på eiendomen"
            onChange={(e) => setQuery(e.target.value)}
          />
        </InputGroup>
      )}
      {adresser && harAdresseMedBruksenhetsnummer(adresser) && (
        <Flex gap={2} alignItems="center">
          <Button
            variant="secondary"
            colorScheme="green"
            marginTop={5}
            marginBottom={5}
            marginLeft={1}
            onClick={openAdressemerker}
          >
            Lag adressemerker
          </Button>
          <InfoPopover color="blue">
            <Text>
              Lager alle adressemerkene for valgt eiendom. Les mer om
              adressemerker på{" "}
              <Link
                href="https://www.kartverket.no/eiendom/adressering/adressemerker"
                isExternal
              >
                kartverket.no
              </Link>{" "}
            </Text>
          </InfoPopover>
        </Flex>
      )}
      {rows.length > 0 ? (
        <PanelData caption="Adresser" headers={headers} rows={rows} />
      ) : (
        <Text as="i" textColor="gray.500">
          Ingen adresser i listen samsvarer med søketeksten.
        </Text>
      )}
    </AccordionPanelWrapper>
  );
};
