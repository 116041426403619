import { Center, Heading, Text, VStack } from "@kvib/react";

export const ErrorPage = () => {
  return (
    <Center height="100vh">
      <VStack spacing={4}>
        <Heading as="h1" size="xl">
          Oops!
        </Heading>
        <Text>Beklager, en uventet feil har oppstått.</Text>
        <Text>Prøv igjen senere.</Text>
      </VStack>
    </Center>
  );
};
