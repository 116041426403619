import {
  Box,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepStatus,
  StepTitle,
} from "@kvib/react";

import AnimatedStepSeparator from "./AnimatedStepperSeparator";

type Props = {
  activeStep: number;
  steps: { title: string }[];
};

const AnimatedStepper = ({ activeStep, steps }: Props) => {
  return (
    <Stepper index={activeStep} width="70%">
      {steps.map((step, index) => {
        const isCompleted = index < activeStep;

        return (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box flexShrink="0">
              <StepTitle>{step.title}</StepTitle>
            </Box>

            <AnimatedStepSeparator isCompleted={isCompleted} />
          </Step>
        );
      })}
    </Stepper>
  );
};

export default AnimatedStepper;
