import { useAuthenticationFlow } from "@kartverket/frontend-aut-lib";
import {
  Button,
  Flex,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Show,
  Spacer,
  useMediaQuery,
} from "@kvib/react";
import React from "react";
import { ContentContainer } from "shared/components/ContentContainer";
import LogoIcon from "shared/icons/LogoIcon";

import { Driftsmelding } from "../../shared/components/Driftsmelding";

export const Header: React.FC = () => {
  const [isAbove550px] = useMediaQuery("(min-width: 550px)");
  const { isAuthenticatedFunc, handleLogoutFunc } = useAuthenticationFlow();
  const isAuthenticated = isAuthenticatedFunc();

  const padding = isAbove550px ? "30px" : "10px";

  return (
    <>
      <Flex
        borderBottom="1px solid var(--kvib-colors-gray-50)"
        paddingTop={padding}
        paddingBottom={padding}
      >
        <ContentContainer>
          <Flex alignItems="center">
            <Link href="/" aria-label="Tilbake til forside">
              <LogoIcon height={isAbove550px ? "50px" : "40px"} />
            </Link>
            <Spacer />
            <Show above="sm">
              <Link style={{ fontWeight: "bold" }} href="/faq">
                Ofte stilte spørsmål
              </Link>
            </Show>
            {isAuthenticated && (
              <Show above="sm">
                <Button
                  colorScheme="green"
                  rightIcon="logout"
                  marginLeft="4"
                  onClick={handleLogoutFunc}
                >
                  Logg ut
                </Button>
              </Show>
            )}
            <Show below="sm">
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon="menu"
                  backgroundColor="white"
                  variant="secondary"
                />
                <MenuList>
                  <MenuItem>
                    <Link href="/faq">Ofte stilte spørsmål</Link>
                  </MenuItem>
                  {isAuthenticated && (
                    <MenuItem>
                      <Link onClick={handleLogoutFunc}>Logg ut</Link>
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            </Show>
          </Flex>
        </ContentContainer>
      </Flex>
      <Driftsmelding />
    </>
  );
};
