import { Box, StepSeparator } from "@kvib/react";
import { motion, useAnimation } from "framer-motion";
import { useEffect, useRef } from "react";

const AnimatedStepSeparator = ({ isCompleted }: { isCompleted: boolean }) => {
  const controls = useAnimation();
  const isFirstRender = useRef(true);

  const transition_time = 0.5;

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      controls.start({ width: isCompleted ? "100%" : "0%" });
    }
  }, [isCompleted, controls]);

  return (
    <Box position="relative" flex={1}>
      <StepSeparator
        style={{ backgroundColor: "lightgrey", position: "absolute" }}
      />
      <motion.div
        style={{
          position: "absolute",
          left: "8px",
          height: "2px",
          backgroundColor: "green",
        }}
        initial={{ width: isCompleted ? "100%" : "0%" }}
        animate={controls}
        transition={{ duration: transition_time }}
      />
    </Box>
  );
};

export default AnimatedStepSeparator;
